.pageHeaderGeneric {
  $p: &;

  display: flex;
  position: relative;
  flex-direction: column;

  @include rwd('tablet') {
    flex-direction: row;
  }

  &__imageCol {
    flex-basis: gridPart(12, 11, 2, gridBase(12, 11, 2)); // 100%
  }

  &__imageWrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    aspect-ratio: 1;

    @include rwd('tablet') {
      overflow: visible;
      aspect-ratio: auto;
    }

    @include parent('#{$p}--reversed', '') {
      width: 100%;
      margin-left: auto;

      @include rwd('tablet') {
        width: gridPart(7, 6, 1, gridBase(12, 11, 2));
      }
    }

    @include rwd('tablet') {
      width: gridPart(8, 8, 1, gridBase(12, 11, 2));
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: cover;

    @include rwd('tablet') {
      opacity: 0;
    }
  }

  &__textWrap {
    display: flex;
    right: 0;
    bottom: 0;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 40px;
    padding-bottom: 8px;
    text-align: left;

    @include rwd('tablet') {
      position: absolute;
      align-items: flex-end;
      width: gridPart(4, 4, 0, gridBase(12, 11, 2));
      margin-right: gridPart(0, 0, 1, gridBase(12, 11, 2));
      margin-bottom: 58px;
      padding-bottom: 0;
      opacity: 0;
      text-align: right;
    }

    @include parent('#{$p}--reversed', '') {
      right: auto;
      left: 0;
      align-items: flex-start;
      margin-right: 0;
      text-align: left;

      @include rwd('tablet') {
        margin-left: gridPart(0, 0, 1, gridBase(12, 11, 2));
      }
    }
  }

  &__imageMask {
    display: none;
    position: absolute;
    top: calc(50% - 270px / 2);
    right: 0;
    left: 0;
    // transform: translateX(0%) translateY(calc(-50% + 135px)) rotate(0deg);
    transform-origin: center left;

    @include parent('#{$p}--reversed', '') {
      left: gridPart(8, 6, 1, gridBase(12, 11, 2)); // calc(40% - 136px);
    }

    @include parent('#{$p}--t', '') {
      top: calc(50% - 1735px / 2);
      left: gridPart(5, 5, 1, gridBase(12, 11, 2));
    }

    @include parent('#{$p}--square', '') {
      top: calc(50% - 1735px / 2);
      left: gridPart(5, 7, 1, gridBase(12, 11, 2));
    }

    @include rwd('tablet') {
      display: block;
    }
  }

  &__imageMaskFull {
    content: ' ';
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: $c-white;

    @include rwd('tablet') {
      display: block;
    }
  }

  &__mask {
    // width: 100%;
    // height: 100%;
    width: 135.5px;
    height: 271px;

    @include parent('#{$p}--t', '') {
      width: 299px;
      height: 1735px;
    }

    @include parent('#{$p}--square', '') {
      width: 269px;
      height: 1735px;
    }
  }

  &__imageMaskCut {
    display: none;
    position: absolute;
    top: calc(50% - 270px / 2);
    right: 0;
    left: 0;
    transform: translateX(0%) translateY(calc(-50% + 135px));
    // transform: translateY(calc(-50% + 135px));

    @include rwd('tablet') {
      display: block;
    }
  }

  &__intro {
    margin-bottom: 8px;
    color: $c-gray-89;
    font-size: 2rem;
    line-height: calc(32 / 20);

    @include rwd('tablet') {
      margin-bottom: 40px;
    }
  }

  &__heading {
    max-width: 450px;
    margin-bottom: 34px;

    @include rwd('tablet') {
      margin-bottom: 104px;
    }

    &--circle {
      margin-bottom: 30px;

      @include rwd('tablet') {
        margin-bottom: 60px;
      }
    }

    &--square {
      @include rwd('tablet') {
        margin-bottom: 90px;
      }
    }

    &--t {
      @include rwd('tablet') {
        margin-bottom: 35px;
      }
    }
  }

  &__seeMore {
    display: block;
    position: relative;
    padding-left: 30px;
    transition: color $tr;
    color: $c-gray-89;
    font-size: 1.6rem;
    font-weight: $fw-regular;
    line-height: calc(19 / 16);

    &:hover {
      color: $c-gray-6a;
    }

    &::after {
      @include icon;
      @include icon-left(20px, 20px);
      content: $icon-scroll;
      animation: mouseScroll 2.75s ease-in-out infinite;
      // content: $icon-scroll-empty;
    }

    @include rwd('tablet') {
      margin-top: 54px;
    }
  }

  @keyframes mouseScroll {
    0%   { transform: translateY(0); }
    25%  { transform: translateY(0); }
    50%  { transform: translateY(0); }
    75%  { transform: translateY(.3em); }
    100% { transform: translateY(0); }
  }

  &__link {
    position: relative;
    transition: color $tr;
    color: $c-black;

    &::after {
      @include icon;
      @include icon-right(44px, 44px);
      @include fluid-font(4.4, 5.4);
      content: $icon-plus-thin;
      right: -58px;
      width: auto;
      height: auto;
      margin-top: -16px;
      transition: color $tr, transform $tr ease-out;
      color: $c-black;
      line-height: normal;

      @include rwd('large-tablet') {
        right: -72px;
      }
    }

    &:hover {
      color: $c-gray-6a;

      &::after {
        // transform: scaleY(-1);
        color: $c-gray-6a;
      }
    }
  }
}
