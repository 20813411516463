@use 'sass:math';

.wysiwyg {
  p {
    // @include fluid-font(1.7, 1.7);
    font-size: 1.8rem;
    font-weight: $fw-regular;
    line-height: math.div(29, 18);

    + p {
      margin-top: 10px;
    }
  }

  ul {
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      &::before {
        content: '•';
        display: inline-block;
        padding-right: 14px;
        padding-left: 17px;
        vertical-align: baseline;
      }
    }
  }

  ol {
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      counter-increment: my-eon-counter;

      &::before {
        content: counter(my-eon-counter) '.';
        display: inline-block;
        padding-right: 10px;
        padding-left: 17px;
        vertical-align: baseline;
      }
    }
  }

  li {
    // @include fluid-font(1.7, 1.7);
    font-size: 1.8rem;
    font-weight: $fw-regular;
    line-height: math.div(29, 18);

  }

  blockquote {
    @include fluid-font(3.5, 5.7);
    margin-top: 40px;
    margin-bottom: 40px;
    color: $c-cta;
    font-style: italic;
    font-weight: $fw-light;
    line-height: (57 * .02);
    text-decoration: underline;
    text-decoration-thickness: from-font; // fluid-font clamp() breaks the default underline thickness

    @include rwd('tablet') {
      line-height: math.div(42, 35);
    }
  }

  h1,
  h2 {
    @include fluid-font(2.5, 3.5);
    @include fluid-spacing('margin-top', 30, 50);
    @include fluid-spacing('margin-bottom', 14, 20);
    font-style: italic;
    font-weight: $fw-bold;
    line-height: math.div(36, 35);

    @include rwd('large-phone') {
      line-height: math.div(36, 25);
    }
  }

  h3 {
    @include fluid-font(2, 2.5);
    @include fluid-spacing('margin-top', 26, 50);
    @include fluid-spacing('margin-bottom', 12, 16);
    font-style: italic;
    font-weight: $fw-bold;
    line-height: math.div(31, 25);

    @include rwd('large-phone') {
      line-height: (31 * .05);
    }
  }

  h4 {
    @include fluid-font(1.7, 1.7);
    @include fluid-spacing('margin-top', 24, 50);
    @include fluid-spacing('margin-bottom', 10, 10);
    font-style: italic;
    font-weight: $fw-bold;
    line-height: math.div(31, 17);

    @include rwd('large-phone') {
      line-height: math.div(31, 17);
    }
  }

  strong,
  b {
    font-weight: $fw-bold;
  }

  em,
  i {
    font-style: italic;
  }

  a {
    // display: inline-block;
    // transition: text-decoration-color $tr;
    // color: inherit;
    // text-decoration: underline;
    // text-decoration-color: inherit;

    display: inline;
    transition: box-shadow $tr;
    box-shadow:
      inset 0 0 $c-white,
      inset 0 -.075em $c-black;
    color: inherit;

    &:hover {
      // text-decoration-color: inherit;
      box-shadow:
        inset 0 -.05em $c-white,
        inset 0 -.125em $c-black;
    }

    &[target] {
      position: relative;
      padding-right: 14px;
      color: inherit;

      &::before {
        @include icon;
        @include icon-right(16px, 16px);
        content: '⧉';
        top: 0;
        color: inherit;
      }
    }
  }
}
