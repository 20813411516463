.margin {
  $sizes: 0 1 2 3 4 5;

  @function calcSize($value) {
      @return $value * 10px;
  }

  @each $size in $sizes {
    &-#{$size} {
      margin: calcSize($size);

      &-l {
        margin-left: calcSize($size);
      }

      &-r {
        margin-right: calcSize($size);
      }

      &-t {
        margin-top: calcSize($size);

        @if $size != 0 {
          &-neg {
            margin-top: -#{calcSize($size)};
          }
        }
      }


      &-b {
        margin-bottom: calcSize($size);

        @if $size != 0 {
          &-neg {
            margin-bottom: -#{calcSize($size)};
          }
        }
      }
    }
  }
}

/*

Generates generic styles like:

margin-1 {
  margin: 10px;
}

.margin-1-l {
  margin-left: 10px;
}

.margin-1-r {
  margin-right: 10px;
}

.margin-1-t {
  margin-top: 10px;
}

.margin-1-t-neg {
  margin-top: -10px;
}

*/
