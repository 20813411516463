.materialList {
  $p: &;

  &__modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    // max-width: 1140px;
    height: 100%;
    // margin-left: auto;
    padding: 110px 20px 40px;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    // transition: transform $tr-l, opacity $tr $tr;
    opacity: 0;
    // background-color: $c-gray-25;
    color: $c-gray-ac;

    @include rwd('tablet') {
      padding-top: 68px;
      padding-right: gridPart(2, 1, 1, gridBase(12, 11, 2));
      padding-bottom: 124px;
      padding-left: gridPart(2, 1, 1, gridBase(12, 11, 2));
    }

    &.active {
      z-index: 1;
      transform: translateX(0);
      // transition: transform $tr-l, opacity $tr;
      opacity: 1;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }

  &__heading {
    display: block;
    margin-bottom: 9px;
    padding-bottom: 46px;
    transform: tramslateY(-40px);
    border-bottom: 1px solid $c-gray-74;
    opacity: 0;
    color: $c-white;
    pointer-events: none;

    @include rwd('tablet') {
      pointer-events: auto;
    }
  }

  &__headingMobile {
    padding-bottom: 42px;
    color: $c-white;
    font-size: 4.8rem;
    font-weight: $fw-semibold;
    letter-spacing: .02rem;
    line-height: calc(42 / 48);

    @include rwd('tablet') {
      display: none;
    }
  }

  &__minus {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -48px;
      width: 32px;
      height: 1px;
      margin-top: 4px;
      background-color: $c-white;
    }
  }

  &__materials {
    &--marginTop {
      max-height: 0;
      overflow: hidden;
      transition: max-height $tr-l;

      #{$p}__material {
        &:first-of-type {
          margin-top: 40px;
        }
      }
    }
  }

  &__list {
    padding-bottom: 110px;
  }

  // &__material {
  //   transform: translateY(40px);
  //   opacity: 0;
  // }

  &__img {
    width: 100%;
    height: 136px;
    object-fit: cover;

    @include rwd('tablet') {
      display: none;
    }
  }

  &__link {
    transition: color $tr;
    color: $c-gray-89;

    &:hover {
      color: $c-white;
    }
  }

  &__label {
    display: block;
    z-index: 1;
    margin-top: 16px;
    transition: color $tr;

    @include rwd('tablet') {
      @include fluid-spacing('margin-top', 16, 40);
      @include fluid-spacing('margin-bottom', 16, 22);
    }

    @include parent('#{$p}__link', ':hover') {
      color: $c-white;
    }
  }

  &__title {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-bottom: 16px;
    color: $c-white;
    font-size: 3.2rem;
    letter-spacing: -.01rem;
    line-height: calc(48 / 32);

    @include rwd('tablet') {
      @include fluid-font(4.2, 6.4);
      margin-bottom: auto;
      color: $c-white;
      letter-spacing: -.2rem;
      line-height: calc(64 / 64);
    }

    &::after {
      @include icon;
      @include icon-right(44px, 44px);
      content: $icon-arrow-down;
      right: -34px;
      margin-top: -5px;
      transition: opacity $tr;
      opacity: 0;
      font-size: 20px;
      line-height: 20px;

      @include rwd('tablet') {
        @include fluid-spacing('margin-top', calc(30 / -5.5), calc(44 / -5.5));
        @include fluid-spacing('right', -54, -80);
        @include fluid-spacing('width', 30, 44);
        @include fluid-spacing('height', 30, 44);
        @include fluid-spacing('line-height', 30, 44);
        @include fluid-font(3, 4.4);
      }
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  &__footer {
    padding-top: 70px;
    border-top: 1px solid $c-gray-74;
  }

  &__ctaText {
    font-size: 1.6rem;

    @include rwd('tablet') {
      font-size: 1.8rem;
    }
  }

  &__ctaLink {
    position: relative;
    margin-top: 12px;
    color: $c-white;
    font-size: 4.8rem;
    letter-spacing: .02rem;
    line-height: calc(42 / 48);

    @include rwd('tablet') {
      @include fluid-font(6, 8);
      margin-top: 24px;
      line-height: 1;
    }

    &::after {
      @include icon;
      @include icon-right(44px, 44px);
      content: $icon-arrow-down;
      @include fluid-spacing('margin-top', calc(30 / -5.5), calc(44 / -5.5)); // 20
      @include fluid-spacing('right', -45, -66);
      @include fluid-spacing('width', 30, 44);
      @include fluid-spacing('height', 30, 44);
      @include fluid-spacing('line-height', 30, 44);
      @include fluid-font(3, 4.4);
    }
  }

  &__preview {
    position: absolute;
    top: 120px;
    right: gridPart(1, 1, 1, gridBase(12, 11, 2));
    width: gridPart(6, 5, 0, gridBase(12, 11, 2));
    overflow: hidden;
    transition: opacity $tr;
    opacity: 0;
    pointer-events: none;
    aspect-ratio: 586 / 526;

    &.active {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
