.socialIcons {
  $p: &;

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    position: relative;
    padding: 4px;
  }
}
