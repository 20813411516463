.menu {
  $p: &;

  display: none;
  padding-left: 0;

  @include rwd('tablet') {
    display: block;
  }

  @include rwd('large-tablet') {
    padding-left: 5%;
  }

  @include rwd('laptop') {
    padding-left: gridPart(1, 2, 0, gridBase(12, 11, 0));
  }

  // @include rwd('tablet') {
  //   display: none;
  // }

  &__items {
    @include fluid-spacing('gap', 10, 32);
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    @include rwd('tablet') {
      justify-content: flex-end;
    }
  }

  &__link.linkMenu--plus {
    @include rwd('tablet') {
      margin-right: 20px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

/*   &__menu {
    margin-left: 70px;
    padding: 4px 0;
    float: left;

    @include rtl {
      margin-right: 70px;
      margin-left: 0;
      float: right;
    }

    @include rwd('laptop') {
      margin-left: 30px;
      padding: 8px 0 0;

      @include rtl {
        margin-right: 30px;
      }
    }

    @include rwd('phone') {
      margin-left: 20px;
    }
  }

  &__menuItems {
    @include clear;
    margin: 0 -20px;

    @include rwd('tablet') {
      pointer-events: none;
    }

    @include rwd('laptop') {
      display: block;
      position: absolute;
      width: 277px;
      margin-top: 36px;
      padding-top: 14px;
      padding-bottom: 10px;
      transform: translateY(50px);
      transition: transform $tr, opacity $tr, visibility $tr;
      border: 0;
      background-color: $c-white;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;

      &.is-active {
        transform: translateY(0);
        border: 1px solid $c-gray;
        border-top: 1px solid transparent;
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
      }

      @include parent('.header', '.header--sticky') {
        margin-top: 15px;

        @include rwd('large-phone') {
          max-height: calc(100vh - 60px);
          overflow-y: auto;
        }
      }
    }

    @include rwd('large-phone') {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
    }

    @include rwd('phone') {
      left: 17px;
      width: calc(100vw - 34px);
      margin: 36px 0 0;
    }
  }

  &__menuItem {
    position: relative;
    float: left;
    font-size: 0;

    @include rtl {
      float: right;
    }

    @include rwd('laptop') {
      width: 100%;
    }

    &--hasSubmenu {
      &:after {
        // @include icomoon('arrow-small-down');
        display: block;
        position: absolute;
        top: calc(50% - 3px);
        right: 20px;
        transition: transform $tr;
        color: $c-cta;
        font-size: 6px;
        line-height: 1;

        @include rtl {
          top: 12px;
          right: initial;
          left: 20px;
        }
      }

      .is-active {
        &:after {
          transform: rotate(180deg);
        }
      }

      @include rwd('laptop') {
        &:after {
          top: 12px;
        }
      }
    }

    &--tabletAndDown {
      display: none;

      @include rwd('tablet') {
        display: block;
      }
    }
  }

  &__menuItemLink,
  &__menuSubmenuItemLink {
    display: inline-block;

    &:before,
    &:after {
      padding: 0 20px;
      color: $c-cta;
      font-size: 1.6rem;
      line-height: 2;

      @include parent('.header__menuItem', '.header__menuItem--hasSubmenu') {
        padding-right: 43px;

        @include rtl {
          padding-right: 20px;
          padding-left: 43px;
        }
      }
    }

    &:after {
      opacity: .5;
    }
  }

  &__menuItemLink {
    @include rwd('laptop') {
      width: 100%;
      z-index: 1;

      &:focus+.header__menuSubmenu {
        max-height: 500px;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__menuSubmenu {
    position: absolute;
    top: 100%;
    left: 20px;
    min-width: 160px;
    transform: translateY(50px);
    transition: opacity $tr, transform $tr, visibility $tr, border-top $tr;
    border-top: 34px solid transparent;
    opacity: 0;
    visibility: hidden;

    @include rtl {
      right: 20px;
      left: auto;
    }

    @include rwd('laptop') {
      transform: translateY(0);
      border-top: 1px solid transparent;
    }

    @include parent('.header', '.header--sticky') {
      border-top: 14px solid transparent;

      @include rwd('laptop') {
        border-top: 1px solid transparent;
      }
    }

    @include parent('.header__menuItem--hasSubmenu', '.is-active') {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;

      @include rwd('laptop') {
        opacity: 0;
      }
    }

    @include rwd('laptop') {
      position: relative;
      top: initial;
      left: initial;
      max-height: 0;
      transition: max-height $tr $tr, opacity $tr $tr;
      border-top: 0;
      overflow: hidden;
      visibility: visible;

      @include parent('.header__menuItem--hasSubmenu', '.is-active .header__menuItemLink:focus + ') {
        opacity: 1;
      }
    }
  }

  &__menuSubmenuItems {
    padding: 11px 15px;
    border-radius: 0 0 5px 5px;
    background-color: $c-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .08);

    @include rwd('laptop') {
      padding-top: 0;
      padding-left: 30px;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &__menuSubmenuItemLink {

    &:before,
    &:after {
      padding: 0 !important;
      font-size: 1.4rem;
      line-height: 2;
    }
  } */
}
