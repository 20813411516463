.wrapper {
  position: relative;
  width: 100%;
  max-width: $width + 2 * 30;
  margin: 0 auto;
  padding: 0 30px;

  &--fill {
    height: 100%;
  }

  &--thin {
    max-width: $width + 2 * 30;
  }

  &--full {
    max-width: $width + 2 * 30;
    padding: 0;
  }

  @include rwd('tablet') {
    padding: 0 15px;
  }
}
