.reusable {

  &__flexRow {
    display: flex;
    flex-wrap: wrap;
  }

  &__pre {
    display: block;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre;
    unicode-bidi: embed;
  }

  &__whiteBack {
    background-color: $c-white;

    @include contrast {
      background-color: $c-wcga-black;
    }
  }

  &__columns {
    column-count: 2;
  }

  &__colorBox {
    width: 75px;
    height: 75px;
    margin-right: 10px;
    border: 1px solid #999;
    border-radius: 5px;
  }

  &__colorHex {
    width: 100px;
  }

  &__3cols {
    column-count: 3;
  }

  &__color {
    width: 300px;
    height: 60px;
    margin: 10px;
    padding: 10px;
    border: 1px solid $c-gray;
    font-weight: 600;
    break-inside: avoid-column;
  }
}
