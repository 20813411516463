.cookieBox {
  // @include fluid-font(1.2, 1.2);
  visibility: hidden;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 468px;
  padding: 16px;
  transform: translateX(50%) translateZ(0);
  transition: transform $tr, opacity $tr, visibility $tr;
  border: 1px solid #f6f6f6;
  opacity: 0;
  background-color: $c-white;
  color: $c-gray-6a;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: calc(16 / 12);
  pointer-events: none;

  @include rwd('tablet') {
    // left: 35px;
    // bottom: 35px;
    padding: 16px;
  }


  &.visible {
    visibility: visible;
    transform: translateX(0);
    transition: none;
    opacity: 1;
  }

  &__button {
    margin-right: 20px;
    padding: 8px 0 0;
    transition: border-color $tr, color $tr;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    color: $c-gray-6a;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .1rem;
    line-height: calc(18 / 11);
    cursor: pointer;
    pointer-events: auto;

    &:hover {
      // border-bottom-color: rgba($c-gray-89, .7);
      color: $c-black;
    }
  }

  &__close {
    // border-bottom: 1px solid rgba($c-gray-ac, .7);
    color: $c-black;
    font-weight: $fw-semibold;
  }
}
