.button {
  $p: &;

  display: inline-block;
  position: relative;
  padding-right: 20px;
  transition: color $tr, background-color $tr, border-color $tr, opacity $tr;
  color: $c-black;
  font-size: 1.6rem;
  font-weight: $fw-semibold;
  line-height: calc(18 / 16);
  white-space: nowrap;
  cursor: pointer;

  &::after {
    @include icon;
    @include icon-right(11px, 11px);
    content: $icon-arrow-small;
    right: 0;
    transition: transform $tr;
  }

  &.active,
  &:hover {
    color: $c-gray-6a;

    &::after {
      transform: translate(3px, 3px);
    }
  }

  &--up {
    &::after {
      content: $icon-arrow-out;
      margin-top: -4.5px;
    }


    &.active,
    &:hover {
      &::after {
        transform: translate(3px, -3px);
      }
    }
  }

  // &.disabled {
  //   border-color: lighten(grayscale($c-cta), 50%);
  //   background-color: lighten(grayscale($c-cta), 50%);
  //   color: lighten($c-gray, 25%);
  //   pointer-events: none;
  // }
}
