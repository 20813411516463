.pageHeaderContact {
  $p: &;

  // @include fluid-spacing('padding-top', 120, 185);
  // @include fluid-spacing('padding-bottom', 60, 110);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  @include rwd('tablet') {
    flex-direction: row;
    height: 100vh;
  }

  &__imageWrap {
    max-width: 440px;

    @include rwd('tablet') {
      width: gridPart(6, 5, 0, gridBase(12, 11, 0));
      max-width: none;
      padding-right: gridPart(1, 0, 0, gridBase(12, 11, 0));
      padding-left: gridPart(1, 0, 0, gridBase(12, 11, 0));
    }
  }

  &__image {
    width: 100%;
    // transform: rotate(-180deg);

    @include rwd('tablet') {
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__imageMaskFull {
    content: ' ';
    display: none;
    position: absolute;
    top: 0;
    // right: 0;
    right: 50%;
    bottom: 0;
    width: 100vw;
    background-color: $c-gray-e6;

    @include rwd('tablet') {
      display: block;
    }
  }

  &__textWrap {
    width: 100%;
    padding-top: 40px;

    @include rwd('tablet') {
      width: gridPart(6, 6, 0, gridBase(12, 11, 0));
      padding-top: 30px;
      padding-left: gridPart(1, 2, 0, gridBase(12, 11, 0));
      opacity: 0;
    }
  }

  &__text {
    @include fluid-spacing('padding-top', 24, 40);
    max-width: gridPart(4, 3, 0, gridBase(6, 6, 0));
    color: $c-gray-6a;
    font-weight: $fw-regular;
  }

  &__contacts {
    @include fluid-spacing('margin-top', 20, 28);
  }

  &__contact {
    margin-top: 16px;
  }

  &__label {
    margin-top: 16px;
  }

  &__link {
    display: block;
    transition: color $tr;
    color: $c-black;

    &:hover {
      color: $c-gray-6a;
    }
  }

  &__see {
    @include fluid-spacing('margin-top', 40, 54);
    display: block;
    position: relative;
    padding-left: 30px;
    transition: color $tr;
    color: $c-gray-89;
    font-size: 1.6rem;
    font-weight: $fw-regular;
    line-height: calc(19 / 16);

    &:hover {
      color: $c-gray-6a;
    }

    &::after {
      @include icon;
      @include icon-left(20px, 20px);
      content: $icon-scroll;
      animation: mouseScroll 2.75s ease-in-out infinite;
      // content: $icon-scroll-empty;
    }

    // &::before {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 4px;
    //   left: 9px;
    //   width: 1px;
    //   height: 3.5px;
    //   animation: mouseRollScroll 2.75s ease-in-out infinite;
    //   border-radius: inherit;
    //   background: $c-black;
    // }
  }

  @keyframes mouseScroll {
    0%   { transform: translateY(0); }
    25%  { transform: translateY(0); }
    50%  { transform: translateY(0); }
    75%  { transform: translateY(.3em); }
    100% { transform: translateY(0); }
  }

  // @keyframes mouseRollScroll {
  //   0%   { transform: translateY(0); opacity: 1; }
  //   25%  { opacity: 1; }
  //   75%  { transform: translateY(.5em); opacity: 0; }
  //   100% { transform: translateY(0); opacity: 0; }
  // }
}
