@use 'sass:math';

a {
  &:not([class]) {
    display: inline-block;
    position: relative;
    transition: box-shadow $tr;
    box-shadow:
      inset 0 0 $c-white,
      inset 0 -.075em $c-black;
    color: inherit;

    &:hover {
      // text-decoration-color: inherit;
      box-shadow:
        inset 0 -.05em $c-white,
        inset 0 -.125em $c-black;
    }

    &[target] {
      position: relative;
      padding-right: 14px;
      transition: color $tr;
      box-shadow: none;
      color: $c-black;
      font-weight: $fw-extrabold;

      &:hover {
        color: inherit;

        &::before {
          transform: translate(2px, -2px);
        }
      }

      &::before {
        @include icon;
        @include icon-right(12px, 12x);
        content: $icon-arrow-out;
        top: 35%;
        padding-left: 4px;
        transition: transform $tr;
        color: inherit;
      }
    }
  }
}
