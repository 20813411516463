
// @keyframes downAndFastBackUp {
// 	0% { transform: translate(0, 0); }
// 	95% { transform: translate(0, 6px); }
// 	100% { transform: translate(0, 0); }
// }

@keyframes upAndDown {
  0% { transform: translate(0, 0); }
  50% { transform: translate(0, 6px); }
  75% { transform: translate(0, -6px); }
  100% { transform: translate(0, 0); }
}

// @keyframes downAndUp {
// 	0% { transform: translate(0, 0); }
// 	50% { transform: translate(0, 6px); }
// 	100% { transform: translate(0, 0); }
// }

@keyframes fluctuate {
  0% { fill: $c-back; }
  40% { fill: $c-back; }
  50% { fill: rgba($c-back, .2); }
  60% { fill: $c-back; }
  100% { fill: $c-back; }
}

@keyframes maxToHide {
  0% { opacity: 0; }
  30% { opacity: .8; }
  60% { opacity: .8; }
  100% { opacity: 0; }
}
