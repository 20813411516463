.showOnScroll {
  $p: &;

  @include rwd('tablet', 'up') {
    opacity: 0;

    &--hidden {
      transform: translateY(100px);

      &#{$p}--left {
        transform: translateX(-100px);
      }

      &#{$p}--right {
        transform: translateX(100px);
      }

      &#{$p}--circle {
        transform: scale(0);
      }
    }

    &--active {
      transform: translateY(0);
      transition: transform .7s, opacity 1s;
      opacity: 1;

      &#{$p}--left {
        transform: translateX(0);
      }

      &#{$p}--right {
        transform: translateX(0);
      }

      &#{$p}--circle {
        transform: scale(1);
      }
    }
  }

  // turn off for IE11
  // @include browser('ie') {
  //   opacity: 1;

  //   &--hidden {
  //     transform: translateY(0);

  //     &#{$p}--left {
  //       transform: translateX(0);
  //     }

  //     &#{$p}--right {
  //       transform: translateX(0);
  //     }

  //     &#{$p}--circle {
  //       transform: scale(1);
  //     }
  //   }
  // }
}
