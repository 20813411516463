.padding {
  $sizes: 0 1 2 3 4 5;

  @function calc-size($value) {
      @return $value * 10px;
  }

  @each $size in $sizes {
    &-#{$size} {
      padding: calc-size($size);

      &-l {
        padding-left: calc-size($size);
      }

      &-r {
        padding-right: calc-size($size);
      }

      &-t {
        padding-top: calc-size($size);
      }

      &-b {
        padding-bottom: calc-size($size);
      }
    }
  }
}

/*

Generates generic styles like:

padding-1 {
  padding: 10px;
}

.padding-1-l {
  padding-left: 10px;
}

.padding-1-r {
  padding-right: 10px;
}

.padding-1-t {
  padding-top: 10px;
}

.padding-1-t-neg {
  padding-top: -10px;
}

*/
