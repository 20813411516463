@use 'sass:math';

.logo {
  $p: &;

  max-height: 60px;
  transition: max-height $tr;

  // @include rwd('laptop') {
  //   max-height: none;
  // }

  // @include rwd('tablet') {
  //   margin: 0 auto;
  // }

  &__link {
    display: inline-block;
    // height: 100%;
    background-color: transparent;
    cursor: pointer;

    // @include rwd('tablet') {
    //   width: 220px;
    // }

    // @include rwd('large-phone') {
    //   width: 144px;
    // }
  }

  &__image {
    display: inline-block;
    width: 76px;
    height: 31px;
    transition: width $tr, height $tr;

    @include rwd('tablet') {
      width: 144px;
      height: 60px;

      @at-root .header--sticky.header--stickyUp & {
        width: 118px;
        height: 48px;
      }
    }

    &--white {
      display: none;
    }
  }
}



// yeah it's ugly :(
.header:not(.header--sticky) {
  .menu--white {
    .logo__image {
      display: none;
    }

    .logo__image--white {
      display: block;
    }
  }
}
