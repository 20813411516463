.twoImages {
  $p: &;

  display: flex;
  flex-direction: column;
  @include fluid-spacing('margin-top', 48, 160);

  &__images {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    gap: 16px;

    @include rwd('tablet') {
      flex-direction: row;
      gap: gridPart(0, 1, 0, gridBase(12, 11, 0));
    }

    &--top {
      align-items: flex-start;
    }
  }

  &__image {
    display: block;
    width: 100%;

    & + & {
      @include fluid-spacing('margin-bottom', 40, 45);
    }
  }

  &__text {
    @include fluid-spacing('margin-top', 40, 45);
    display: block;
    width: 100%;
    color: $c-gray-6a;
    font-size: 1.8rem;
    line-height: calc(30 / 18);

    @include rwd('tablet') {
      width: gridPart(4, 3, 0, gridBase(12, 11, 0));
      margin-left: gridPart(6, 6, 0, gridBase(12, 11, 0));
    }

    &--noMargin {
      margin-top: 0;
    }
  }
}
