/**
* Generates generic font weight classes like:

.fw-hairline {
  font-weight: 100;
}

.fw-thin {
  font-weight: 200;
}

.fw-light {
  font-weight: 300;
}

[...]

*/

$fw-hairline: 100;
$fw-thin: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-black: 800;

$fw-types: (
  'hairline' $fw-hairline,
  'thin' $fw-thin,
  'light' $fw-light,
  'regular' $fw-regular,
  'medium' $fw-medium,
  'semibold' $fw-semibold,
  'bold' $fw-bold,
  'black' $fw-black,
);


@each $fw-type-name, $fw-type-var in $fw-types {
  .fw-#{$fw-type-name} {
    font-weight: $fw-type-var;
  }
}
