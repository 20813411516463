.blockquote {
  $p: &;

  @include fluid-font(2.6, 4);
  @include fluid-spacing('padding-top', 124, 136);
  max-width: (840px + 2 * $padding);
  margin: 0 auto;
  padding-right: #{$padding}px;
  padding-left: #{$padding}px;
  font-weight: $fw-light;
  line-height: calc(56/40);
  text-align: center;

  @include rwd('large-phone') {
    padding-right: #{$padding-phone}px;
    padding-left: #{$padding-phone}px;
  }

  @include parent('#{$p}--hr', '') {
    padding-bottom: 136px;

    @include rwd('tablet') {
      padding-bottom: 88px;
    }
  }

  &__header {
    padding-bottom: 36px;
    color: $c-cta;
    font-size: 1rem;
    font-weight: $fw-medium;
    line-height: calc(15/10);
    text-transform: uppercase;
    letter-spacing: .2rem;
  }

  &--homepage {
    @include fluid-spacing('padding-bottom', 120, 176);
    @include fluid-spacing('padding-top', 60, 72);
  }

  &__inner {
    @include parent('#{$p}--homepage', '') {
      position: relative;
    }
  }

  &--hr {
    position: relative;
    padding-top: 16px;

    @include rwd('tablet') {
      padding-top: 16px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 1px;
      background-color: $c-gray-c8;
    }
  }
}
