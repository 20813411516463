@use 'sass:math';

@mixin fluid-spacing($attr, $min-px, $max-px) {

  @if ($max-px > 0) {

    $vpw: #{math.div($max-px, $fluid-max-width + (2 * $padding)) * 100}vw;
    #{$attr}: Max(#{$min-px}px, Min(#{$vpw}, #{$max-px}px)); // sass-lint:disable-line function-name-format
    #{$attr}: clamp(#{$min-px}px, #{$vpw}, #{$max-px}px); // sass-lint:disable-line no-duplicate-properties

    // .isOldDesktopSafari &,
    // .isOldMobileSafari & {
    //   #{$attr}: #{$min-px}px;
    // }
  } @else {

    $vpw: #{math.div($max-px, $fluid-max-width + (2 * $padding)) * 100}vw;
    #{$attr}: Min(#{$max-px}px, Max(#{$vpw}, #{$min-px}px)); // sass-lint:disable-line function-name-format
    #{$attr}: clamp(#{$max-px}px, #{$vpw}, #{$min-px}px); // sass-lint:disable-line no-duplicate-properties

    // .isOldDesktopSafari &,
    // .isOldMobileSafari & {
    //   #{$attr}: #{$max-px}px;
    // }
  }
}

/*

Genrates fluid padding / margin - best used for vertical spacing:
 - attribute to be matched
 - minimal margin / padding in px
 - maximum margin / padding in px

!!! Note! It has to be: $min-rem != $max-rem


  @include fluid-spacing('padding-top', 20, 74);

results in:

  padding-top: clamp(20px, 5.4093567251vw, 74px);

*/
