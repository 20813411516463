// $icomoon-font-path: '../assets/icomoon/fonts' !default;

@import '../../../assets/icomoon/variables';
@import '../../../assets/icomoon/style';

@mixin icon {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  // speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @function unicode($str) {
// 	@return unquote('\'') + $str + unquote('\'');
// }

.icons {
  &__reusable {
    display: flex;
    align-items: center;
    padding: 4px 0;
    font-family: courier;
    font-size: 1.6rem;
    font-weight: 600;
    break-inside: avoid;

    .icon {
      padding-right: 10px;
      font-size: 3.6rem;
      vertical-align: middle;
    }

    span + span {
      cursor: pointer;
    }
  }

  &__reusableWrap {
    column-count: 3;
  }
}

