@use 'sass:math';

@mixin fluid-font($min-rem, $max-rem) {

  $vpw: #{math.div($max-rem * 10, $fluid-max-width + (2 * $padding)) * 100}vw;

  // @warn ($fluid-max-width + (2 * $padding));

  font-size: Max(#{$min-rem}rem, Min(#{$vpw}, #{$max-rem}rem)); // sass-lint:disable-line function-name-format
  font-size: clamp(#{$min-rem}rem, #{$vpw}, #{$max-rem}rem); // sass-lint:disable-line no-duplicate-properties
}

/*

Genrates fluid font size, given:
 - minimal font size in rems
 - expected font size in pixels for full width container
 - maximum font size in rems

!!! Note! It has to be: $max-rem != $min-rem


  @include fluid-font(3, 6.6);

results in:

  font-size: clamp(3rem, 4.8245614035vw, 6.6rem);

*/
