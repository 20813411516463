.headerFlexible {
  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: (840px + 2 * $padding);
    margin: 0 auto;
    padding: 0 #{$padding}px;
    text-align: center;

    @include rwd('large-phone') {
      padding: 0 #{$padding-phone}px;
    }
  }

  &__services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 30px;

    @include rwd('tablet') {
      margin-top: 40px;
    }

    @include rwd('large-phone') {
      gap: 12px;
    }
  }

  &__background {
    @include fluid-spacing('margin-top', 60, 112);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    min-height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // aspect-ratio: 1366 / 632;

    @include rwd('tablet') {
      min-height: auto;
    }
  }

  &__circle {
    position: relative;
    pointer-events: none;

    &::before {
      --header-flexible-radius: 1140px;
      $radius: 1140px;
      content: '';
      position: absolute;
      z-index: 1;
      top: calc(var(--header-flexible-radius)/-2);
      left: calc(50% - (var(--header-flexible-radius)/2));
      width: var(--header-flexible-radius);
      height: var(--header-flexible-radius);
      border: 1px solid $c-cta;
      border-radius: 50%;
      pointer-events: none;

      @include rwd('tablet') {
        --header-flexible-radius: 540px;
      }
    }
  }
}
