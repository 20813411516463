.photoSlider {
  @include fluid-spacing('margin-top', 80, 160);
  position: relative;

  // &__swiper {
  //   aspect-ratio: calc(1366 / 675);
  // }

  &__swiper {
    height: 610px;
    margin-right: -20px;
    margin-left: -20px;

    @include rwd('tablet') {
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }

    &.disabled {
      .swiper-wrapper {
        justify-content: center;
        transform: translate3d(0, 0, 0) !important;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: auto;
      bottom: 60px;
      transition: background-color $tr;

      @include rwd('tablet') {
        bottom: 114px;
      }

      &::after {
        @include icon;
        @include icon-center(35px, 35px);
        transition: color $tr;
        color: $c-white;

        @include rwd('tablet') {
          @include icon-center(45px, 45px);
        }
      }
    }

    .swiper-button-prev {
      left: 20px;

      @include rwd('tablet') {
        left: gridPart(0, 0, 1, gridBase(12, 11, 2));
      }

      &::after {
        content: $icon-arrow-left;

        @include rwd('tablet') {
          content: $icon-arrow-left-slider;
        }
      }
    }

    .swiper-button-next {
      left: calc(20px + 64px);

      @include rwd('tablet') {
        left: calc(gridPart(0, 0, 1, gridBase(12, 11, 2)) + 80px);
      }

      &::after {
        content: $icon-arrow-right;

        @include rwd('tablet') {
          content: $icon-arrow-right-slider;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      &:hover {
        &::after {
          color: $c-gray-ac;
        }
      }
    }

    .swiper-pagination {
      position: absolute;
      z-index: 1;
      top: 276px;
      left: 20px;
      color: $c-white;
      font-family: $ff-sec;
      font-size: 1.2rem;
      letter-spacing: .18rem;
      line-height: calc(16 / 12);

      @include rwd('tablet') {
        top: 126px;
        left: gridPart(0, 0, 1, gridBase(12, 11, 2));
      }
    }

    .swiper-pagination-line {
      display: inline-block;
      position: relative;
      width: (40 + 2 * 18px);
      padding: 0 18px;

      &::before {
        content: '';
        position: absolute;
        bottom: 5px;
        left: calc(40px / 2);
        width: 40px;
        height: 1px;
        background-color: $c-white;
      }
    }

    // .swiper-slide {
    //   // max-width: gridPart(3, 2, 0, gridBase(12, 11, 0));
    //   // max-width: 280px;
    // }
  }

  &__slide {
    // display: flex;
    // position: relative;
    // box-sizing: border-box;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: space-between;
    width: 100%;
    min-width: 0;
    // max-width: 280px;
    height: 100%;
    min-height: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // max-height: 427px;
    // background-color: $c-gray-c8;
    color: $c-white;
    cursor: pointer;
    aspect-ratio: calc(1366 / 675);
  }

  &__slideContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;

    @include rwd('tablet') {
      padding-right: gridPart(0, 0, 1, gridBase(12, 11, 2));
      padding-left: gridPart(6, 6, 1, gridBase(12, 11, 2));
    }
  }

  &__contentTop {
    padding-top: 64px;

    @include rwd('tablet') {
      padding-top: 120px;
    }
  }

  &__contentBottom {
    padding-bottom: 130px;

    @include rwd('tablet') {
      padding-bottom: 102px;
    }
  }

  &__label {
    padding-bottom: 8px;
    color: $c-white;
  }

  &__text {
    font-size: 2.8rem;
    font-weight: $fw-semibold;
    line-height: calc(45 / 28);
  }

  &__headline {
    @include fluid-spacing('margin-top', 64, 120);
    position: absolute;
    z-index: 2;
    padding-right: gridPart(0, 0, 1, gridBase(12, 11, 2));
    padding-left: gridPart(0, 0, 1, gridBase(12, 11, 2));
    color: $c-white;
    pointer-events: none;

    @include rwd('tablet') {
      padding-left: gridPart(6, 6, 1, gridBase(12, 11, 2));
    }
  }

  &__contentOverlay {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 192px;
    left: 0;
    color: $c-white;

    @include rwd('tablet') {
      bottom: 172px;
      left: gridPart(6, 6, 1, gridBase(12, 11, 2));
    }
  }

  &__labelText {
    position: absolute;
  }
}
