$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-materials: "\e90e";
$icon-blocks: "\e901";
$icon-scroll: "\e902";
$icon-scroll-empty: "\e911";
$icon-close: "\e903";
$icon-close-thin: "\e914";
$icon-plus-thin: "\e904";
$icon-minus-thin: "\e917";
$icon-plus: "\e905";
$icon-download: "\e906";
$icon-arrow-small: "\e907";
$icon-arrow-out: "\e908";
$icon-arrow-down: "\e909";
$icon-arrow-down-thin: "\e900";
$icon-arrow-up: "\e90a";
$icon-arrow-left: "\e90f";
$icon-arrow-right: "\e90b";
$icon-arrow-long-up: "\e90c";
$icon-arrow-long-right: "\e910";
$icon-arrow-long-left: "\e90d";
$icon-arrow-right-thin: "\e912";
$icon-arrow-left-thin: "\e913";
$icon-arrow-right-slider: "\e915";
$icon-arrow-left-slider: "\e916";

