.sharpener {
  &__img {
    filter: url('#sharpener');
  }

  &__filter {
    // display: none;
    position: absolute;
    top: 0;
    pointer-events: none;
  }
}
