.offerSquares {
  $p: &;

  display: flex;
  flex-direction: column;
  @include fluid-spacing('padding-top', 80, 160);

  &__heading {
    @include fluid-spacing('margin-bottom', 40, 72);
    text-align: center;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include rwd('tablet') {
      flex-direction: row;
      aspect-ratio: 2 / 1;
    }
  }

  &__squareWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 424px;
    padding: 40px 32px 48px;
    transition: margin $tr;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include rwd('tablet') {
      width: 50%;
      height: auto;
      padding: 75px;

      &:hover {
        margin-bottom: -28px;
      }
    }

    &--white {
      color: $c-black;
    }

    &--black {
      color: $c-white;
    }
  }

  &__label {
    margin-bottom: 32px;

    @include rwd('tablet') {
      margin-bottom: 54px;
    }

    @include parent('#{$p}__squareWrap--black', '') {
      color: $c-white;
    }

    @include parent('#{$p}__squareWrap--white', '') {
      color: $c-black;
    }
  }

  &__icon {
    position: relative;
    width: 100px;
    height: 100px;

    &::after {
      @include icon;
      @include icon-center(100px, 100px);
      // color: $c-cta;
    }

    &--blocks {
      &::after {
        content: $icon-blocks;
      }
    }

    &--materials {
      &::after {
        content: $icon-materials;
      }
    }

    @include parent('#{$p}__squareWrap', ':hover') {
      &::after {
        content: $icon-arrow-down-thin;
        width: 70px;
        height: 70px;
        margin-top: -35px;
        margin-left: -35px;
        font-size: 70px;
        line-height: 70px;
      }
    }
  }
}
