@use 'sass:math';

// sass-lint:disable class-name-format
.p {
  // @include fluid-font(1.6, 1.8);
  font-size: 1.8rem;
  font-weight: $fw-regular;
  line-height: math.div(30, 18);

  &--caps {
    text-transform: uppercase;
  }

  // &--gray {
  //   opacity: (59 * 0.01);
  // }

  // &--small {
  //   @include fluid-font(1.4, 1.4);
  // }

  &--large {
    @include fluid-font(1.8, 2.4);
    line-height: calc(36/24);
  }

  // &--xlarge {
  //   @include fluid-font(2.2, 2.2);
  // }

  & + & {
    margin-top: 30px;
  }

  p + p {
    margin-top: 30px;
  }
}
