.hamburger {
  // based on https://jonsuh.com/hamburgers/
  $p: &;
  $line-width: 20px;
  $line-height: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  transition: border-color $tr;
  border: 1px solid $c-white;
  border-radius: 50%;
  // background-color: $c-white;

  &.reverse {
    // background-color: $c-black;
    border: 1px solid $c-black;
  }

  &.active {
    border: 1px solid $c-white;
  }

  &__inner {
    display: inline-block;
    position: relative;
    width: $line-width;
    height: 22px;
    cursor: pointer;
  }

  &__lines {
    display: block;
    position: absolute;
    top: 50%;
    width: $line-width;
    height: $line-height;
    margin-top: -($line-height * 0.5);
    transition: transform $tr;
    border-radius: 0;
    background-color: transparent;

    @include parent('#{$p}', '.active') {
      transform: rotate(45deg);
      transition-delay: $tr-s;
    }

    &::before,
    &::after {
      display: block;
      position: absolute;
      width: $line-width;
      height: $line-height;
      transition: background-color $tr;
      border-radius: 0;
      background-color: $c-white;
      content: '';
    }

    &::before {
      top: -4px;
      transition: top $tr $tr-s, opacity $tr;

      @include parent('#{$p}', '.active') {
        top: 0;
        transition: top $tr, opacity $tr $tr-s;
        background-color: $c-white;
        opacity: 1;
      }
    }

    &::after {
      bottom: -4px;
      transition: bottom $tr $tr-s, transform $tr;

      @include parent('#{$p}', '.active') {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom $tr, transform $tr $tr-s;
        background-color: $c-white;
      }
    }

    @include parent('#{$p}', '.reverse') {
      &::before,
      &::after {
        background-color: $c-black;
      }
    }
  }
}
