.materialDetails {
  display: flex;
  flex-direction: column;

  @include rwd('tablet') {
    flex-direction: row;
  }

  &__left {
    max-height: 375px;
    overflow: hidden;

    @include rwd('tablet') {
      width: gridPart(6, 5, 1, gridBase(12, 11, 2));
      max-height: none;
      overflow: visible;
    }
  }

  &__right {
    position: relative;
    padding: 40px 20px 0;

    @include rwd('tablet') {
      width: gridPart(6, 6, 1, gridBase(12, 11, 2));
      padding-right: gridPart(0, 0, 1, gridBase(12, 11, 2));
      padding-left: gridPart(1, 2, 0, gridBase(12, 11, 2));
      opacity: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    aspect-ratio: 352 / 375;
    // aspect-ratio: 765 / 669;

    @include rwd('tablet') {
      position: fixed;
      width: gridPart(6, 5, 1, gridBase(12, 11, 2));
      aspect-ratio: auto;
    }
  }

  &__imageMaskFull {
    content: ' ';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: $c-gray-25;

    @include rwd('tablet') {
      display: block;
    }
  }

  &__heading {
    @include rwd('tablet') {
      @include fluid-spacing('margin-top', 180, 295);
      max-width: 480px;
      margin-top: 230px;
      margin-left: auto;
      text-align: right;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 56px;
    padding-top: 35px;
    padding-bottom: 35px;
    border-top: 2px solid $c-white;
    border-bottom: 2px solid $c-gray-74;

    @include rwd('tablet') {
      margin-top: 54px;
      margin-bottom: 64px;
      padding-top: 42px;
      padding-bottom: 42px;
    }
  }

  &__headerItem {
    color: $c-white;
    font-size: 1.8rem;
    font-weight: $fw-semibold;
    line-height: calc(22 / 18);

    &:nth-child(1) {
      width: gridPart(2, 1, 0, gridBase(5, 4, 0));
    }

    &:nth-child(2) {
      width: gridPart(2, 2, 0, gridBase(5, 4, 0));
    }

    &:nth-child(3) {
      width: gridPart(1, 1, 0, gridBase(5, 4, 0));
      text-align: right;
    }

    span {
      display: block;
      margin-bottom: 9px;
      text-transform: uppercase;
    }
  }

  &__map {
    margin-left: auto;
  }

  &__specs {
    margin-top: 42px;
  }

  &__spec {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
  }

  &__specParam {
    width: gridPart(4, 4, 0, gridBase(5, 4, 0));
    margin-bottom: 28px;
    padding-right: gridPart(1, 2, 0, gridBase(5, 4, 0));
    line-height: calc(21 / 16);

    span {
      display: block;
      margin-top: 8px;
      line-height: calc(17 / 12);
    }
  }

  &__specValue {
    width: gridPart(1, 0, 0, gridBase(5, 4, 0));
    font-size: 1.6rem;
    font-weight: $fw-bold;
    line-height: calc(21 / 16);
  }

  &__downloads {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 56px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid $c-gray-74;
    border-bottom: 1px solid $c-white;

    @include rwd('tablet') {
      flex-direction: row;
      align-items: flex-end;
      margin-top: 46px;
      padding-top: 45px;
      padding-bottom: 48px;
      border-top: 2px solid $c-gray-74;
      border-bottom: 2px solid $c-white;
    }
  }

  &__download {
    color: inherit;
  }

  &__pdfs {
    position: relative;
    width: auto;
    margin-top: 18px;
    margin-bottom: 2px;
    padding-right: 36px;
    transition: color $tr;
    font-size: 1.6rem;
    font-weight: $fw-semibold;
    line-height: calc(18 / 16);
    text-align: left;

    @include rwd('laptop') {
      width: 80%;
      margin-top: auto;
      text-align: right;
    }

    &::after {
      @include icon;
      @include icon-right(20px, 20px);
      content: $icon-download;
      // color: $c-cta;
    }

    &:hover {
      color: $c-gray-c8;
    }
  }

  &__cta {
    margin-top: 38px;
    margin-bottom: 40px;

    @include rwd('tablet') {
      margin-top: 72px;
      margin-bottom: 50px;
    }
  }

  &__ctaText {
    color: $c-gray-ac;
    font-size: 1.6rem;
    font-weight: $fw-regular;
    line-height: calc(21 / 16);
  }

  &__ctaLink {
    display: inline-block;
    position: relative;
    margin-top: 25px;
    padding-right: 72px;
    transition: color $tr;
    color: inherit;
    font-size: 3.2rem;

    @include rwd('tablet') {
      @include fluid-font(4.2, 6.4);
    }

    &::after {
      @include icon;
      @include icon-right(46px, 46px);
      content: $icon-arrow-down;
      right: 30px;
      margin-top: -5px;
      font-size: 20px;
      line-height: 20px;
      // transition: opacity $tr;
      // opacity: 0;

      @include rwd('tablet') {
        right: 0;
        margin-top: -20px;
        font-size: 46px;
        line-height: 46px;
      }
    }

    &:hover {
      color: $c-gray-c8;
    }
  }
}
