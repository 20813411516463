// https://codepen.io/fromwireframes/pen/arMrYp

.burger {
  $p: &;

  display: block;
  position: fixed;
  z-index: 30;
  top: 5px;
  right: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;

  @include rwd('tablet') {
    display: none;
  }

  &#{$p}--white {
    span {
      background-color: $c-white;
    }
  }

  &__checkbox {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    -webkit-touch-callout: none;
  }

  &__lines {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // width: 26÷px;
    width: 1 * 30px;
    height: 9px;
    margin: auto;
  }

  span {
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 2px;
    transition:
      background-color $tr ease-in,
      transform $tr ease-in,
      top $tr ease-in,
      bottom $tr ease-in,
      max-width $tr ease-in;
    background-color: $c-black;

    @include parent('.theme-black', '') {
      background-color: $c-white;
    }

    &:first-of-type {
      top: 0;
    }

    &:last-of-type {
      bottom: 0;
    }
  }

  &.active,
  &__checkbox:checked + .burger__lines {
    span {
      background-color: $c-white;

      &:first-of-type {
        top: 4px;
        transform: rotate(45deg);
      }

      &:last-of-type {
        bottom: 3px;
        // max-width: 30px;
        max-width: 1 * 30px;
        transform: rotate(-45deg);
      }
    }
  }

  // &.active:hover span:first-of-type,
  // &.active:hover span:last-of-type,
  // &:hover .burger__checkbox:checked + .burger__lines span:first-of-type,
  // &:hover .burger__checkbox:checked + .burger__lines span:last-of-type {
  //   width: 22px;
  // }

  // &:hover {
  //   // no need hover effect on mobile.
  //   @media (min-width: 1024px) {
  //     span {
  //       &:first-of-type {
  //         width: 26px;
  //       }

  //       &:last-of-type {
  //         width: 12px;
  //       }
  //     }
  //   }
  // }
}
