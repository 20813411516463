.footer {
  $p: &;

  @include fluid-spacing('padding-top', 90, 160);
  position: relative;
  z-index: 1;
  width: 100%;

  @include parent('.theme-black', '') {
    padding-top: 0;
    background-color: $c-gray-25;
  }

  @include parent('.theme-gray', '') {
    background-color: $c-gray-e6;
  }

  &__main {
    @include fluid-spacing('padding-top', 80, 136);
    @include fluid-spacing('padding-bottom', 56, 122);
    display: flex;
    position: relative;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;

    @include parent('.theme-black', '') {
      background-color: $c-gray-25;
      color: $c-white;
    }

    @include parent('.theme-gray', '') {
      background-color: $c-white;
    }

    @include rwd('tablet') {
      flex-direction: row;
      padding-right: gridPart(0, 0, 1);
      padding-left: gridPart(0, 0, 1);
    }
  }

  &__mainLeft {
    display: flex;
    flex-direction: column;
    // width: 30%;

    @include rwd('tablet') {
      width: gridPart(5, 5, 0, gridBase(12, 11, 0));
    }

    // @include rwd('large-tablet') {
    //   width: gridPart(5, 5, 0, gridBase(12, 11, 0));
    // }
  }

  &__mainRight {
    // width: 70%;
    margin-top: 32px;

    @include rwd('tablet') {
      width: gridPart(7, 6, 0, gridBase(12, 11, 0));
      margin-top: 0;
    }

    // @include rwd('large-tablet') {
    //   width: gridPart(7, 6, 0, gridBase(12, 11, 0));
    //   margin-top: 0;
    // }
  }

  &__imageWrap {
    height: 288px;

    > div {
      height: 100%;
    }

    @include rwd('tablet') {
      height: auto;

      > div {
        height: auto;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include rwd('tablet') {
      height: auto;
      object-fit: fill;
    }
  }

  &__cta {
    color: $c-gray-ac;
    font-size: 1.5rem;
    font-weight: $fw-semibold;
    line-height: calc(18 / 15);
  }

  &__contact {
    @include fluid-spacing('margin-top', 16, 42);
  }

  &__contactLink {
    @include fluid-font(2.8, 4);
    transition: color $tr;
    color: $c-black;
    font-weight: $fw-semibold;
    letter-spacing: -.118rem;
    line-height: calc(48 / 40);

    @include parent('.theme-black', '') {
      color: $c-white;
    }

    &:hover {
      color: $c-gray-6a;
    }
  }

  &__logoWrap {
    position: absolute;
    bottom: 120px;

    > img {
      height: 32px;
    }

    @include rwd('large-tablet') {
      position: static;
      bottom: auto;
      height: auto;
      margin-top: 122px;

      > img {
        height: auto;
      }
    }
  }

  &__logo {
    width: 112px;
    height: auto;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include rwd('tablet') {
      align-items: flex-end;
    }
  }

  &__menuItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 146px;

    @include rwd('large-tablet') {
      flex-wrap: no-wrap;
      justify-content: normal;
      width: auto;
      margin-bottom: 0;
      gap: 40px;
    }
  }

  &__menuItem {
    width: 50%;
    margin-top: 18px;

    @include rwd('large-tablet') {
      width: auto;
      margin-top: 0;
    }
  }

  &__menuItemLink.linkMenu--plus {
    margin-right: 20px;
  }

  &__menuItemLink--lang {
    margin-top: 18px;
    margin-left: 0;

    @include rwd('large-tablet') {
      margin-top: 0;
      margin-left: 32px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
    color: $c-gray-ac;
    font-size: 1.2rem;
    line-height: calc(14.5 / 12);
    gap: 40px;

    @include rwd('large-tablet') {
      justify-content: flex-end;
    }
  }

  &__privacyLink {
    transition: color $tr;
    color: $c-gray-ac;

    &:hover {
      color: $c-gray-89;
    }

    &::before {
      display: none;
    }
  }
}
