.lightbox {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: opacity $tr;
  background-color: rgba($c-black, .95);
  opacity: 0;
  z-index: 100;
  pointer-events: none;

  &--show {
    opacity: 1;
    pointer-events: all;
  }

  &__galleryWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    max-width: 1200px;
    height: 80vh;
    max-height: 1000px;
    margin: auto;
  }

  &__swiperTopWrapper,
  &__swiperBottomWrapper {
    display: flex;
    position: relative;
    align-items: center;
  }

  &__swiperTopWrapper {
    margin-bottom: 80px;
  }

  &__gallery {
    width: 100%;
    height: 80%;
  }

  &__btn {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    border-radius: 50%;
    background-color: $c-white;
    color: $c-black;
    cursor: pointer;
    z-index: 111;
  }

  &__galleryPrev {
    top: calc(50% - 20px);
    left: -5%;
    transition: left $tr;

    &:hover {
      left: -5.5%;
    }
  }

  &__galleryNext {
    top: calc(50% - 20px);
    right: -5%;
    transition: right $tr;

    &:hover {
      right: -5.5%;
    }
  }

  &__thumbPrev {
    bottom: calc(50% - 20px);
    left: -5%;
    transition: left $tr;

    &:hover {
      left: -5.5%;
    }
  }

  &__thumbNext {
    right: -5%;
    bottom: calc(50% - 20px);
    transition: right $tr;

    &:hover {
      right: -5.5%;
    }
  }

  &__thumbs {
    width: 100%;
    height: 20vw;
    min-height: 100px;
    max-height: 200px;
    margin-top: 0;
  }

  &__image {
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__imageImg {
    max-width: 100%;
    margin: auto;
  }

  &__thumb {
    width: 20vw;
    min-width: 100px;
    max-width: 200px;
    transition: opacity $tr;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    opacity: .4;

    &:hover,
    &.swiper-slide-active {
      opacity: 1;
    }
  }

  &__btnClose {
    align-self: flex-end;
    margin: 20px;

    .icon {
      &:first-child {
        margin-right: -4px;
      }

      &:last-child {
        margin-left: -4px;
      }
    }

    // @include rtl {
    //   .icon {
    //     &:first-child {
    //       order: 1;
    //     }
    //   }
    // }
  }
}
