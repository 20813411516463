.photoLinks {
  justify-content: center;
  width: 100%;
  max-height: 680px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__inner {
    @include fluid-spacing('padding-top', 88, 208);
    @include fluid-spacing('padding-bottom', 88, 198);
  }

  &__header {
    width: 100%;
    max-width: (840 + 2px * $padding);
    margin: 0 auto;
    padding-right: #{$padding}px;
    padding-left: #{$padding}px;
    text-align: center;

    @include rwd('large-phone') {
      padding-right: #{$padding-phone}px;
      padding-left: #{$padding-phone}px;
    }
  }

  &__background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__links {
    @include fluid-spacing('margin-top', 56, 80);
    display: flex;
    justify-content: center;
    gap: 20px;

    @include rwd('tablet') {
      flex-direction: column;
      align-items: center;
    }
  }
}
