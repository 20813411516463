.textSlider {
  @include fluid-spacing('padding-top', 90, 136);
  @include fluid-spacing('padding-bottom', 88, 132);
  @include fluid-spacing('margin-bottom', 54, 136);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: $c-gray-c8;
  }

  &--noHr {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  &__texts {
    @include fluid-font(6, 9);
    display: flex;
    font-family: $f-sec;
    font-weight: $fw-regular;
    line-height: calc(94/90);
    white-space: nowrap;
  }

  &__text {
    @include fluid-spacing('padding-left', calc(90/2), calc(136/2));
    @include fluid-spacing('padding-right', calc(90/2), calc(136/2));
    position: relative;
  }

  &__link {
    position: relative;
    display: block;
    transition: color $tr;

    &::after {
      position: absolute;
      bottom: -22px;
      left: 0;
      width: 100%;
      height: 2px;
      transition: background-color $tr;
      background-color: transparent;
      content: '';
    }

    &:hover {
      color: $c-gray-ac;

      &::after {
        background-color: rgba($c-gray-ac, .8);
      }
    }
  }

  &__circle {
    position: relative;
    pointer-events: none;

    &::before {
      --text-slider-radius: 1140px;
      $radius: 1060px;
      content: '';
      position: absolute;
      z-index: 1;
      top: calc(50% - (var(--text-slider-radius)/2));
      left: calc(50% - (var(--text-slider-radius)/2));
      width: var(--text-slider-radius);
      height: var(--text-slider-radius);
      border: 1px solid $c-cta;
      border-radius: 50%;
      pointer-events: none;

      @include rwd('tablet') {
        --text-slider-radius: 540px;
      }
    }
  }
}

.section--text_slider {
  overflow-x: hidden;
}
