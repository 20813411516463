@mixin abs-center($w, $h) {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: $w;
  height: $h;
  margin-top: -$h * 0.5;
  margin-left: -$w * 0.5;
}

@mixin icon-center($w, $h) {
  font-size: $w;
  line-height: $w;
  text-align: center;
  @include abs-center($w, $h);
}

@mixin icon-right($w, $h) {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: $w;
  height: $h;
  margin-top: -$h * 0.5;
  font-size: $w;
  line-height: $w;
  text-align: right;
}

@mixin icon-left($w, $h) {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: $w;
  height: $h;
  margin-top: -$h * 0.5;
  font-size: $w;
  line-height: $w;
  text-align: left;
}
