.section {
  $vspace: 136;

  // @include fluid-spacing('padding-top', 80, $vspace);
  // @include fluid-spacing('padding-bottom', 80, $vspace);

  &:first-of-type {
    padding-top: #{$header-height}px;
    padding-bottom: 112px;

    @include rwd('tablet') {
      padding-bottom: 80px;
    }

    @include rwd('large-phone') {
      padding-top: #{$header-height-mobile}px;
    }
  }

  // &--lessPadding {
  //   // @include fluid-spacing('padding-top', 64, 96);
  //   // @include fluid-spacing('padding-bottom', 64, 96);
  // }

  &--overflowX {
    overflow-x: hidden;
  }

  &--coffee-milk {
    background-color: $c-gray-c8;
  }

  &--dark-green {
    background-color: $c-gray-89;
    color: $c-white;
  }

  &--white {
    background-color: $c-white;
  }

  &--brown {
    background-color: $c-gray-6a;
  }

  &--topFluid {
    @include fluid-spacing('padding-top', 80, 136);
  }

  &--bottomFluid {
    @include fluid-spacing('padding-bottom', 80, 136);
  }

  &--noPT {
    padding-top: 0;
  }

  &--noPB {
    padding-bottom: 0;

    &:first-of-type {
      padding-bottom: 0;
    }
  }

  &--circleFull,
  &--circle {
    position: relative;

    &::before {
      $radius: 994px;
      content: '';
      position: absolute;
      z-index: 1;
      top: calc($radius/-2);
      left: calc(50% - ($radius/2) + 300px);
      width: $radius;
      height: $radius;
      border: 1px solid $c-cta;
      border-radius: 50%;
      pointer-events: none;
    }
  }

  &--circle {
    overflow: hidden;
  }

  &--hr {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: #{$padding}px;
      left: #{$padding}px;
      height: 1px;
      background-color: $c-gray-c8;

      @include rwd('large-phone') {
        right: #{$padding-phone}px;
        left: #{$padding-phone}px;
      }
    }
  }

  // & + & {

  //   // @include parent('.sections', '.home') {
  //   //   margin-top: $section-margin-top-large;

  //   //   @include rwd('tablet') {
  //   //     margin-top: $section-margin-top-tablet;
  //   //   }
  //   // }
  // }

  // &--center {
  //   text-align: center;
  // }

  // &--noMargin {
  //   margin-top: 0 !important;
  // }

  // &--mt50 {
  //   margin-top: 50px;
  // }

  // &--mt100 {
  //   @include fluid-spacing('margin-top', 50, 100);
  // }

  // &--mb100 {
  //   margin-bottom: 100px;
  // }

  // @include rwd('large-phone') {
  //   &--mt100 {
  //     margin-top: 60px;
  //   }

  //   &--mb100 {
  //     margin-bottom: 60px;
  //   }
  // }

  // &--lightGray {
  //   background-color: rgba($c-gray, .05);
  // }
}
