.linkMenu {
  $p: &;

  // @include fluid-font(1, 1.2);
  display: inline-block;
  position: relative;
  transition: color $tr;
  color: $c-black;
  font-size: 1.7rem;
  font-weight: $fw-semibold;
  line-height: calc(18 / 15);
  text-decoration: none;
  white-space: nowrap;

  &:hover,
  &.active {
    color: $c-gray-6a;
  }

  @include parent('.theme-black', '') {
    color: $c-white;

    &:hover,
    &.active {
      color: $c-gray-c8;
    }
  }


  // &.active {
    // color: $c-gray-6a;

    // &::after {
    //   content: ' ';
    //   position: absolute;
    //   right: 0;
    //   bottom: -13px;
    //   left: 0;
    //   width: 100%;
    //   height: 1px;
    //   background-color: $c-gray-89;
    // }

    // &:hover {
    //   &::after {
    //     height: 2px;
    //   }
    // }
  // }

  &--lang {
    margin-top: 18px;
    text-transform: uppercase;

    @include rwd('tablet') {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  &--plus {
    position: relative;

    &::after {
      @include icon;
      @include icon-right(12px, 12px);
      content: $icon-plus;
      right: -16px;
      margin-top: -5px;
      transition: color $tr, transform $tr ease-out;
      color: $c-black;

      @include rwd('tablet') {
        right: -20px;
      }
    }

    &:hover,
    &.active {
      &::after {
        transform: rotate(180deg);
        // transform: scaleY(-1);
        color: $c-gray-6a;
      }
    }

    @include parent('.theme-black', '') {
      &::after {
        color: $c-gray-c8;
      }

      &:hover,
      &.active {
        &::after {
          color: $c-gray-c8;
        }
      }
    }
  }

  &--inverted {
    color: $c-white;

    &:hover {
      color: $c-white;
    }

    &.active {
      color: $c-white;

      &::after {
        background-color: $c-cta;
      }
    }
  }
}

// yeah it's ugly :(
.header:not(.header--sticky) .menu--white {
  .linkMenu {
    color: $c-white;

    &:hover {
      color: $c-white;
    }

    &.active {
      color: $c-white;

      &::after {
        background-color: $c-cta;
      }
    }
  }
}
