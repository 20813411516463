// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import '_setup/reset';
@import '_setup/vars';
@import '_setup/colors';
@import '_setup/fonts';


// @import 'vendors/parallax';
// @import 'vendors/tiny-slider';
// @import 'vendors/scrollbar';

// @import '~swiper/swiper-bundle.min.css';

@import '_setup/global';

@import '_helpers/functions';
@import '_helpers/mixins';
@import '_helpers/browser';
@import '_helpers/autofill';
@import '_helpers/contrast';
@import '_helpers/placeholder';
@import '_helpers/parent';
@import '_helpers/rwd';
// @import '_helpers/rtl';

@import '_helpers/gridFunctions';
// @import '_helpers/gridPreview';
// @import '_helpers/gridTest';
@import '_helpers/touch';

@import '_helpers/animations';
@import '_helpers/fluidAttribute';
@import '_helpers/fluidFont';
@import '_helpers/fluidSpacing';
@import '_helpers/icons';
@import '_helpers/iconsEmbed';
@import '_helpers/hiddenVisually';
@import '_helpers/sharpener';
// @import '_helpers/ratio';

@import 'vendors/showOnScroll';
@import 'vendors/simpleParallax';
// @import 'vendors/swiper';

@import 'atoms/a';
@import 'atoms/background';
// @import 'atoms/blockquote';
@import 'atoms/button';
// @import 'atoms/buttonLink';
@import 'atoms/buttonScrollTop';
@import 'atoms/burger';
@import 'atoms/hamburger';
@import 'atoms/heading';
@import 'atoms/image';
// @import 'atoms/langSwitch';
@import 'atoms/label';
@import 'atoms/link';
@import 'atoms/linkMenu';
// @import 'atoms/linkUnderlined';
@import 'atoms/p';

// @import 'molecules/forms/input';
// @import 'molecules/forms/checkbox';
// @import 'molecules/forms/radio';
// @import 'molecules/forms/textarea';

@import 'molecules/wysiwyg';

@import '_layout/wrapper';
@import '_layout/container';
@import '_layout/padding';
@import '_layout/margin';
@import '_layout/sections';
@import '_layout/section';
@import '_layout/main';

@import 'organisms/footer';
@import 'organisms/header';
@import 'organisms/menu';

// @import 'organisms/cardSlider';
// @import 'organisms/hero';
@import 'organisms/cookieBox';
@import 'organisms/materialList';
@import 'organisms/mobileMenu';
@import 'organisms/pageHeader';
// @import 'organisms/downloads';
@import 'organisms/lightbox';
@import 'organisms/textSlider';
// @import 'organisms/videoPopup';

// @import 'organisms/contactDetails';
@import 'organisms/materialDetails';
@import 'organisms/seeAlso';


@import 'organisms/flexible/pageHeaderGeneric';
@import 'organisms/flexible/pageHeaderSimple';
@import 'organisms/flexible/pageHeaderContact';

@import 'organisms/flexible/blockquote';
@import 'organisms/flexible/headerFlexible';
@import 'organisms/flexible/photoPhoto';
@import 'organisms/flexible/photoLinks';

@import 'organisms/flexible/locationBoxes';
@import 'organisms/flexible/offerSquares';
@import 'organisms/flexible/sectionDivider';
@import 'organisms/flexible/textPhoto';
@import 'organisms/flexible/textSection';
@import 'organisms/flexible/twoImages';
@import 'organisms/flexible/teamBoxes';
@import 'organisms/flexible/photoSlider';
// @import 'organisms/flexible/photoCentered';
// @import 'organisms/flexible/photoSlider';
// @import 'organisms/flexible/photoWide';
// @import 'organisms/flexible/textFormatted';

// @import 'molecules/breadcrumbs';
@import 'molecules/footerMenu';
@import 'molecules/modal';
@import 'molecules/reusable';
// @import 'molecules/searchForm';
@import 'molecules/logo';

@import 'molecules/socialIcons';
// @import 'molecules/socialShare';


// @import 'atoms/modal';
// @import 'atoms/wcga';

@import '_helpers/fontWeights';

body {
	font-family: $f-prim;
	font-size: $fs-base;
	font-weight: $fw-regular;

  &.overflow--hidden {
    overflow: hidden;
  }

	// font aliasing tricks
	// -webkit-text-stroke: .45px;
	// -webkit-text-stroke: .45px rgba(0, 0, 0, .5);
	// text-shadow: rgba(0, 0, 0, .2) 0 1px 1px;
}
