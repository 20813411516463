.seeAlso {
  $p: &;
  display: flex;
  z-index: 1;
  flex-direction: column;
  padding: 50px 0;

  @include parent('.theme-black', '') {
    padding: 50px 20px 0;
    background-color: $c-gray-25;
  }

  @include rwd('tablet') {
    flex-direction: row;
    padding: 0;
  }

  &__box {
    position: relative;

    &:first-child {
      width: 100%;
      height: 320px;
    }

    &:last-child {
      width: 100%;
      height: 320px;
    }

    @include rwd('tablet') {
      &:first-child {
        width: gridPart(6, 6, 0, gridBase(12, 11, 0));
        height: auto;
        margin-left: gridPart(0, 0, 1, gridBase(12, 11, 2));
        aspect-ratio: calc(612 / (390 + 28));
      }

      &:last-child {
        width: gridPart(6, 5, 0, gridBase(12, 11, 0));
        height: auto;
        margin-right: gridPart(0, 0, 1, gridBase(12, 11, 2));
        aspect-ratio: calc(586 / (390 + 28));
      }

      // &:hover {
      //   margin-bottom: -28px;
      // }
    }
  }

  &__boxInner {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 34px;
    transition: margin $tr, bottom $tr;
    background-color: $c-black;

    @include rwd('tablet') {
      bottom: 28px;
      padding: 60px;
    }

    &:hover {
      bottom: 0;
    }
  }

  &__cta {
    color: $c-gray-ac;
  }

  &__link {
    display: block;
    margin-top: 16px;
    transition: color $tr;
    color: $c-white;

    @include parent('#{$p}__box', ':hover') {
      color: $c-gray-c8;
    }
  }
}
