.pageHeader {
  $p: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 613px;
  padding: 0 10%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: $c-white;

  &__heading {
    @include fluid-font(4, 6.6);
    max-width: 80%;
    font-weight: normal;
    line-height: 1.167em;

    @include rwd('tablet') {
      max-width: none;
    }

    @include rwd('small-tablet') {
      padding: 0 5%;
    }
  }

  &__subheading {
    @include fluid-spacing('padding-left', 20, 46);
    position: relative;
    max-width: 54%;
    margin-top: 35px;

    // @include rtl {
    //   @include fluid-spacing('padding-right', 20, 46);
    //   padding-left: 0;

    //   &::before {
    //     right: -46px;
    //     left: auto;
    //   }
    // }

    ul {
      li {
        position: relative;

        &::before {
          content: '\2022';
          display: inline-block;
          width: 1em;
          margin-left: -.5em;
          color: $c-cta;
          font-weight: $fw-bold;

          // @include rtl {
          //   margin-right: -.5em;
          //   margin-left: 0;
          // }
        }
      }
    }
  }
}
