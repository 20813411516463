.link {
  $p: &;

  display: inline-block;
  position: relative;
  padding-left: 25px;
  transition: text-decoration-color $tr;
  color: $c-gray;
  font-size: 1.2rem;
  font-weight: $fw-semibold;
  letter-spacing: .1;
  line-height: calc(15/12);
  text-decoration: underline;
  text-transform: uppercase;
  text-decoration-color: transparent;

  &::before {
    @include icon;
    @include icon-left(14px, 14px);
    content: $icon-arrow-left;
    color: inherit;
  }

  &.active,
  &:hover {
    text-decoration-color: $c-cta;
  }

  // &--underline {
  //   text-decoration: underline;
  // }
}
