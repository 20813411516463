.locationBoxes {
  $p: &;

  display: flex;
  flex-direction: column;

  &__heading {
    @include fluid-spacing('margin-top', 80, 160);

    @include rwd('tablet') {
      padding-left: gridPart(1, 1, 0, gridBase(12, 11, 0));
    }
  }

  &__headline {
    font-size: 4rem;
  }

  &__boxesWrap {
    display: flex;
    flex-wrap: wrap;

    @include rwd('tablet') {
      flex-wrap: nowrap;
      padding-left: gridPart(1, 1, 0, gridBase(12, 11, 0));
    }
  }

  &__box {
    width: 100%;
    margin-top: 56px;
    padding-bottom: 56px;
    border-bottom: 1px solid $c-gray-c8;
    font-size: 1.8rem;
    font-weight: $fw-regular;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 1px solid transparent;
    }

    @include rwd('tablet') {
      width: percentage(calc(1 / 3));
      padding-bottom: 0;
      border-bottom: 1px solid transparent;
    }
  }

  &__address {
    margin-top: 32px;
    color: $c-gray-6a;
  }

  &__directions {
    display: inline-block;
    margin-top: 24px;
  }

  &__label {
    margin-top: 50px;
    margin-bottom: 16px;
  }

  &__person {
    font-weight: $fw-semibold;
  }

  &__role {
    color: $c-gray-6a;
    line-height: calc(30 / 18);
  }

  &__phone {
    margin-top: 12px;
    color: $c-gray-6a;
    line-height: calc(30 / 18);
  }

  &__email {
    display: inline-block;
    transition: color $tr;
    color: $c-black;
    line-height: calc(30 / 18);

    &:hover {
      color: $c-gray-6a;
    }
  }

  &__text {
    @include rwd('tablet') {
      padding-left: gridPart(1, 1, 0, gridBase(12, 11, 0));
    }

    margin-top: 60px;
    color: $c-gray-6a;
    font-size: 1.2rem;
    line-height: calc(18 / 12);
  }
}
