@use 'sass:math';

.heading {
  $p: &;

  &__h1 {
    @include fluid-font(6, 8);
    font-family: $f-prim;
    font-weight: $fw-semibold;
    letter-spacing: -.2rem;
    line-height: math.div(80, 80);

    // @include rwd('large-phone') {
    //   line-height: math.div(53, 48);
    // }
  }

  &__h2 {
    @include fluid-font(4.2, 6.4);
    font-family: $f-prim;
    font-weight: $fw-semibold;
    letter-spacing: -.2rem;
    line-height: math.div(64, 64);
  }

  &__h3 {
    @include fluid-font(4.2, 5);
    font-family: $f-prim;
    font-weight: $fw-semibold;
    letter-spacing: -.125rem;
    line-height: math.div(50, 50);
  }

  &__h4 {
    @include fluid-font(3.2, 4);
    font-family: $f-prim;
    font-weight: $fw-semibold;
    letter-spacing: -.1rem;
    line-height: math.div(40, 40);

    @include rwd('large-phone') {
      line-height: math.div(34, 32);
    }
  }

  &__h5 {
    @include fluid-font(2.1, 2.4);
    font-family: $f-prim;
    font-weight: $fw-semibold;
    letter-spacing: -.1rem;
    line-height: math.div(32, 24);
  }

  &__reusable {
    margin: 50px 0 20px;
    padding: 15px 10px 5px;
    border-top-left-radius: 10px;
    background: linear-gradient(to right, #fff, rgba(#fff, 0) 50%);
    color: rgba(23, 26, 136, .85);
    font-family: courier;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    text-transform: initial;
    cursor: pointer;

    & ~ a + a {
      margin-left: 20px;
    }

    & ~ button + button {
      margin-left: 20px;
    }
  }
}
