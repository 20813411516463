.textSection {
  $p: &;

  &__text {
    color: $c-gray-6a;

    @include rwd('tablet') {
      padding-right: gridPart(2, 2, 0, gridBase(12, 11, 0));
      padding-left: gridPart(2, 2, 0, gridBase(12, 11, 0));
    }

    ol,
    ul {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 20px;
    }

    li {
      list-style: disc;
    }

    ol {
      li {
        list-style: decimal;

        li {
          list-style: lower-alpha;
        }
      }
    }

    b,
    strong {
      font-weight: $fw-semibold;
    }

    i,
    em {
      font-style: italic;
    }
  }
}
