.buttonScrollTop {
  position: absolute;
  right: -10px;
  bottom: 34px;
  width: 54px;
  height: 54px;
  transform: translate(-50%, 0);
  transition: border-color $tr, background-color $tr, transform $tr;
  // border: 1px solid rgba($c-gray-ac, .3);
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  @include rwd('large-tablet') {
    right: auto;
    bottom: 10px;
    left: 50%;
  }

  &::after {
    @include icon;
    @include icon-center(24px, 24px);
    content: $icon-arrow-up;
    transition: color $tr;
    color: $c-gray-c8;
  }

  &:hover {
    transform: translate(-50%, -2px);
    // border-color: $c-gray-c8;
    // background-color: $c-gray-ac;

    &::after {
      color: inherit;
    }
  }

  @include parent('.theme-black', '') {
    // background-color: $c-gray-25;
    color: $c-gray-c8;

    &:hover {
      &::after {
        color: $c-white;
      }
    }
  }
}
