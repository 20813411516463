.photoPhoto {
  // @include fluid-spacing('padding-bottom', 80, 136);
  @include rwd('tablet') {
    flex-direction: column;
  }

  &__background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__hfull {
    > div {
      height: 100%;
    }
  }
}
