.mobileMenu {
  $p: &;

  &__modal {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // max-width: 1140px;
    height: 100%;
    margin-left: auto;
    padding: 44px 20px 0;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform $tr-l, opacity $tr $tr;
    opacity: 0;
    background: $c-gray-25;
    pointer-events: none;

    @include rwd('large-phone') {
      padding: 44px 40px 0 20px;
    }

    &.active {
      transform: translateX(0) !important;
      transition: transform $tr-l, opacity $tr;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 32px;
  }

  &__item {
    width: 100%;
    margin-top: 34px;

    &:last-of-type {
      margin-bottom: 54px;
    }
  }

  &__link {
    display: block;
    position: relative;
    transition: color $tr;
    color: $c-white;
    font-family: $ff-prim;
    font-size: 4.8rem;
    font-weight: $fw-semibold;
    letter-spacing: -.02rem;
    line-height: calc(44/40);

    &:hover {
      color: $c-gray-c8;
    }

    &--plus {
      span {
        position: relative;

        &::after {
          @include icon;
          @include icon-right(32px, 32px);
          content: $icon-minus-thin;
          right: -52px;
          margin-top: -12px;
          transition: color $tr, opacity $tr;
          opacity: 0;
          color: $c-white;
        }

        &::before {
          @include icon;
          @include icon-right(32px, 32px);
          content: $icon-plus-thin;
          right: -52px;
          margin-top: -12px;
          transition: color $tr, opacity $tr;
          color: $c-white;
        }
      }

      &.active {
        span {
          &::after {
            opacity: 1;
          }

          &::before {
            opacity: 0;
          }
        }
      }

      &:hover,
      &.active {
        &::after {
          transform: scaleY(-1);
          color: $c-gray-6a;
        }
      }
    }

    // &--active {
    //   &::after {
    //     content: ' ';
    //     position: absolute;
    //     right: 0;
    //     bottom: -13px;
    //     left: 0;
    //     width: 100%;
    //     height: 1px;
    //     background-color: $c-cta;
    //   }

    //   &:hover {
    //     &::after {
    //       height: 2px;
    //     }
    //   }
    // }
  }

  &__langLink {
    transition: color $tr;
    color: $c-gray-ac;
    font-size: 1.8rem;
    font-weight: $fw-semibold;
    line-height: calc(22 / 18);

    &:hover {
      color: $c-white;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 108px;
    min-height: 108px;
    border-top: 1px solid rgba($c-gray-c8, .4);
  }
}
