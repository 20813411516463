.textPhoto {
  $p: &;

  @include fluid-spacing('margin-top', 80, 160);
  display: flex;
  flex-direction: column;
  // @include fluid-spacing('padding-bottom', 8*15, 11*15);

  @include rwd('tablet') {
    flex-direction: row;
  }

  &--reversed {
    flex-direction: column-reverse;

    @include rwd('tablet') {
      flex-direction: row-reverse;
    }
  }

  &__imageWrap {
    width: 100%;
    max-width: 530px;

    @include rwd('tablet') {
      display: flex;
      align-items: center;
      justify-content: center;
      width: gridPart(6, 5, 0, gridBase(12, 11, 0));
      max-width: none;
      text-align: center;
    }

    &--text {
      @include rwd('tablet') {
        align-items: flex-start;
      }
    }

    @include parent('#{$p}--reversed', '') {
      margin-top: 80px;

      @include rwd('tablet') {
        margin-top: auto;
      }
    }
  }

  &__image {
    display: inline-block;
    // width: calc(100% - gridPart(1, 0, 0, gridBase(6, 6, 0)));
    // width: 65%;
    width: 100%;

    @include rwd('tablet') {
      width: 100%;
      padding-right: gridPart(1, 1, 0, gridBase(6, 6, 0));
      padding-left: gridPart(1, 1, 0, gridBase(6, 6, 0));
    }
  }

  &__imageText {
    @include fluid-font(4, 4.8);
    font-weight: $fw-semibold;
    letter-spacing: -.1rem;
    line-height: calc(40 / 40);
    text-align: left;

    @include rwd('tablet') {
      margin-top: 58px;
      margin-right: gridPart(1, 2, 0, gridBase(6, 6, 0));
      margin-left: gridPart(1, 1, 0, gridBase(6, 6, 0));
    }
  }

  &__textWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 530px;
    margin-top: 40px;

    @include parent('#{$p}--reversed', '') {
      margin-top: 0;

      @include rwd('tablet') {
        margin-top: 40px;
        padding-left: gridPart(1, 1, 0, gridBase(12, 11, 0));
      }
    }

    @include rwd('tablet') {
      width: gridPart(6, 6, 0, gridBase(12, 11, 0));
      max-width: none;
      margin-top: 0;
      padding-left: gridPart(0, 1, 0, gridBase(12, 11, 0));
      // width: calc(100% - gridPart(6, 6, 0, gridBase(12, 11, 0))); // gridPart(6, 5, 0, gridBase(12, 11, 0));
    }
  }

  &__intro {
    @include fluid-spacing('margin-bottom', 32, 40);
    // color: $c-gray-ac;
    // font-family: $ff-sec;
    // font-size: 1.2rem;
    // letter-spacing: .2rem;
    // line-height: calc(17 / 12);
    // text-transform: uppercase;
  }

  &__text {
    @include fluid-font(2, 2.4);
    @include fluid-spacing('margin-top', 36, 48);
    font-weight: $fw-semibold;
    line-height: calc(32 / 24);

    @include rwd('tablet') {
      max-width: gridPart(4, 3, 0, gridBase(6, 5, 0));
    }
  }

  &__outro {
    @include fluid-spacing('margin-top', 36, 48);
    // @include fluid-spacing('margin-top', 12, 16);
    color: $c-gray-6a;
    font-size: 1.8rem;
    font-weight: $fw-regular;
    line-height: calc(30 / 18);

    @include rwd('tablet') {
      max-width: gridPart(4, 3, 0, gridBase(6, 5, 0));
    }
  }

  &__link {
    @include fluid-spacing('margin-top', 56, 72);
    position: relative;
    transition: color $tr;
    color: $c-black;
    font-weight: $fw-semibold;
    line-height: calc(18 / 16);

    &::after {
      @include icon;
      @include icon-right(10px, 10px);
      content: $icon-arrow-small;
      right: -18px;
      transition: color $tr, transform $tr;
      color: $c-black;
    }

    &:hover {
      color: $c-gray-6a;

      &::after {
        transform: translate3d(2px, 2px, 0);
        color: $c-gray-6a;
      }
    }
  }
}
