html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: $c-white;
  color: $c-text;
  font-family: $f-prim;
  font-size: $fs-base;
  font-size-adjust: 100%;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

button,
select,
textarea,
input {
  font-family: $f-prim;
}

*:focus {
  outline: none;
  box-shadow: none;
}

*::-moz-focus-inner {
  border: 0;
}

*::placeholder {
  opacity: 1;
}

*::selection {
  background-color: $c-text;
  color: $c-selection;
}

// for iOS
input,
textarea {
  border-radius: 0;
  appearance: none;
}

input {
  &[type='checkbox'] {
    appearance: checkbox;
  }

  &[type='radio'] {
    appearance: radio;
  }

  &[disabled='disabled'],
  &[disabled],

  &:disabled {
    opacity: 1;
  }
}

select { // for iOS
  border-radius: 0;
  // user-select: none;
  text-indent: .01px;
  text-overflow: '';
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

a {
  text-decoration: none;
}

button {
  border: 0;
}
