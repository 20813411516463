// $c-gray-6a:       #24190b;
// $c-gray-89:  #004d3c;
// $c-gray-ac: #008f70;
// $c-coffee-milk: #faf2e8;
// $c-cta:        #e0b955;

$c-gray-25: #252525;
$c-gray-6a: #6a6a6a;
$c-gray-74: #747474;
$c-gray-89: #898989;
$c-gray-ac: #acacac;
$c-gray-c8: #c8c8c8;
$c-gray-d2: #d2d2d2;
$c-gray-e6: #e6e6e6;

$c-white: #fff;
$c-gray: $c-gray-6a;
$c-black: #000;

$c-text: $c-black;
$c-back: $c-white;

// $c-middle-gray: $c-gray;
// $c-label-gray: $c-gray;
// $c-p-gray: $c-gray;
// $c-stroke: $c-gray-c8;

$c-cta: $c-gray;
$c-link: $c-gray;
$c-selection: $c-gray;

$c-error: #d33;
$c-wcga-black:  #0b0c0c;
// $c-wcga-yellow: #ffbf47;
// $c-wcga-hover:  #56f;
// $c-wcga-error:  #ffa155;


// END OF SET //

/* Generates classes like:

.c-gray-66 {
  color: #666;
}

.c-gray-68 {
  color: #686868;
}

[...]

*/

$c-inlines: (
  'white' $c-white,
  'black' $c-black,
  'gray-25' $c-gray-25,
  'gray-6a' $c-gray-6a,
  'gray-74' $c-gray-74,
  'gray-89' $c-gray-89,
  'gray-ac' $c-gray-ac,
  'gray-c8' $c-gray-c8,
  'gray-d2' $c-gray-d2,
  'gray-e6' $c-gray-e6,
  'cta' $c-cta,
);

@each $c-inline-name, $c-inline-var in $c-inlines {
  .c-#{$c-inline-name} {
    color: $c-inline-var;
  }
}


// $colors-list: (red, gray, blue, midnight);
// $colors-map: (red: $c-red, gray: $c-gray, blue: $c-link, midnight: $c-midnight);
