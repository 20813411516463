.sectionDivider {
  @include fluid-spacing('margin-top', 80, 160);
  display: flex;
  flex-direction: row;
  height: 1px;
  margin-right: auto;
  margin-left: auto;
  background-color: $c-gray-c8;

  @include rwd('tablet') {
    width: gridPart(12, 11, 0, gridBase(12, 11, 2));
  }

  &--noMargin {

    @include rwd('tablet') {
      margin-top: 0;
    }
  }
}
