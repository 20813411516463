.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include rwd('tablet') {
    padding-right: gridPart(0, 0, 1);
    padding-left: gridPart(0, 0, 1);
  }

  // @include rwd('phone') {
  //   max-width: $width + (2 * $padding);
  //   padding-right: #{$padding}px;
  //   padding-left: #{$padding}px;
  // }

  &--nopadding {
    padding-right: 0;
    padding-left: 0;
  }

  &--fullheight {
    height: auto;

    @include rwd('tablet') {
      height: 100vh;
    }
  }

  &--overflow {
    position: relative;
    overflow: hidden;
  }
}


// .container {
//   width: 100%;
//   max-width: $width + 2 * $padding;
//   margin-right: auto;
//   margin-left: auto;
//   padding-right: 0;
//   padding-left: 0;

//   &__inner {
//     --padding: #{$padding}px;
//     position: relative;
//     width: 100%;
//     padding-right: var(--padding);
//     padding-left: var(--padding);

//     @include rwd('laptop') {
//       --padding: #{$padding-laptop}px;
//     }

//     @include rwd('tablet') {
//       --padding: #{$padding-tablet}px;
//     }

//     @include rwd('large-phone') {
//       --padding: #{$padding-phone}px;
//     }

//     &--flex {
//       display: flex;
//     }

//     &--nopadding {
//       padding: 0;
//     }
//   }


//   &--fullSize {
//     max-width: none;
//     margin: 0;
//     padding: 0;
//     padding-right: 0;
//     padding-left: 0;
//   }

//   &--overflowHidden {
//     overflow: hidden;
//   }
// }
