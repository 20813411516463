.header {
  $p: &;

  // display: flex;
  position: fixed;
  z-index: 12;
  top: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: #{$header-height-mobile}px;
  padding: 0 20px;
  background-color: transparent;
  pointer-events: none;

  @include rwd('tablet') {
    max-height: #{$header-height}px;
    padding: 0;
    opacity: 0;
    pointer-events: auto;
  }

  &.active {
    transition: background-color $tr, transform $tr;
    opacity: 1;
  }
  // pointer-events: none;

  // @include rwd('large-phone') {
  //   max-height: #{$header-height-mobile}px;
  // }

  // @media print {
  //   position: absolute;
  // }

  &--sticky {
    transform: translateY(-72px);
    background-color: $c-white;

    @include parent('.theme-black', '') {
      background-color: $c-gray-25;
      color: $c-white;
    }

    @include parent('.theme-gray', '') {
      background-color: $c-gray-e6;
    }

    &#{$p}--stickyUp {
      transform: translateY(0);
    }

    // #{$p}__inner {
    //   background-color: $c-white;
    // }

    #{$p}__logo {
      max-height: 48px;
      overflow: hidden;

      // @include rwd('laptop') {
      //   max-height: 32px;
      //   margin-top: 10px;

      // }
    }

    // & + .searchForm {
    //   position: fixed;
    // }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__top {
    display: flex;
    // flex-basis: percentage($number: math.div(45, $header-height));
    // align-items: center;
    // max-height: percentage($number: math.div(45, $header-height));
    // color: $c-white;
    // height: 144px;
    height: 100%;
    font-size: 1.2rem;
    font-weight: $fw-medium;
  }

  &__logo {
    width: 30%;
    pointer-events: auto;

    @include rwd('laptop') {
      width: gridPart(6, 5, 0, gridBase(12, 11, 0));
    }
  }

  &__menu {
    width: 70%;

    @include rwd('laptop') {
      width: gridPart(6, 6, 0, gridBase(12, 11, 0));
    }
  }

  // &__topInner {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding-top: 4px;
  //   padding-bottom: 4px;
  // }

  // &__main {
  //   // flex-basis: percentage($number: math.div($header-height - 45, $header-height));
  //   // max-height: percentage($number: math.div($header-height - 45, $header-height));
  //   background-color: $c-white;
  // }

  // &__mainInner {
  //   display: flex;
  //   justify-content: space-between;
  //   height: 100%;
  //   padding-bottom: 20px;
  //   background-color: $c-white;
  // }

  // &__middle {
  //   display: flex;
  //   flex-direction: column;
  //   flex-grow: 1;
  //   align-items: flex-end;
  //   justify-content: space-between;
  // }

  // &__socials {
  //   display: flex;
  // }

  // &__middleTop {
  //   display: flex;
  //   align-items: center;
  //   width: 100%;
  //   height: 54px;
  //   margin-top: 9px;

  //   transition: max-height $tr, opacity $tr;
  //   overflow: hidden;

  //   @include parent('#{$p}--sticky', '') {
  //     max-height: 0;
  //     opacity: 0;
  //   }

  //   @include parent('#{$p}--stickyUp', '') {
  //     max-height: 61px;
  //     opacity: 1;
  //   }
  // }




  // &__topContainer {
  //   display: flex;
  //   height: 100%;
  // }

  // &__mainContainer {
  //   height: 100%;
  // }



  // &__logoImg {
  //   height: 100%;
  // }

  // &__middleBottom {
  //   display: flex;
  //   justify-content: space-between;
  //   width: 100%;
  // }

}
