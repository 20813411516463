@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?eeynig');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?eeynig#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?eeynig') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?eeynig') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?eeynig##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-materials {
  &:before {
    content: $icon-materials;
  }
}
.icon-blocks {
  &:before {
    content: $icon-blocks;
  }
}
.icon-scroll {
  &:before {
    content: $icon-scroll;
  }
}
.icon-scroll-empty {
  &:before {
    content: $icon-scroll-empty;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-close-thin {
  &:before {
    content: $icon-close-thin;
  }
}
.icon-plus-thin {
  &:before {
    content: $icon-plus-thin;
  }
}
.icon-minus-thin {
  &:before {
    content: $icon-minus-thin;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-arrow-small {
  &:before {
    content: $icon-arrow-small;
  }
}
.icon-arrow-out {
  &:before {
    content: $icon-arrow-out;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-down-thin {
  &:before {
    content: $icon-arrow-down-thin;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-long-up {
  &:before {
    content: $icon-arrow-long-up;
  }
}
.icon-arrow-long-right {
  &:before {
    content: $icon-arrow-long-right;
  }
}
.icon-arrow-long-left {
  &:before {
    content: $icon-arrow-long-left;
  }
}
.icon-arrow-right-thin {
  &:before {
    content: $icon-arrow-right-thin;
  }
}
.icon-arrow-left-thin {
  &:before {
    content: $icon-arrow-left-thin;
  }
}
.icon-arrow-right-slider {
  &:before {
    content: $icon-arrow-right-slider;
  }
}
.icon-arrow-left-slider {
  &:before {
    content: $icon-arrow-left-slider;
  }
}

