.pageHeaderSimple {
  $p: &;

  position: relative;

  &__textWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @include rwd('tablet') {
      margin-bottom: 40px;
      padding-left: gridPart(2, 2, 0, gridBase(12, 11, 0));
    }
  }

  &__heading {
    margin-top: 50px;

    @include rwd('tablet') {
      @include fluid-spacing('margin-top', 160, 270);
    }
  }

  &__date {
    margin-top: 32px;
    color: $c-gray-6a;

    @include rwd('tablet') {
      margin-top: 80px;
    }
  }
}
