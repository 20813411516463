.image {
  $p: &;

  // &--full {
  //   width: 100%;
  //   height: auto;
  // }

  &__wrapper {

    &--background {
      display: flex;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &--max600h {
      min-height: 450px;
      max-height: 600px;
      overflow: hidden;
    }
  }
}
