.modal {
  $p: &;

  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;

  &__backdrop {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: opacity $tr $tr;
    opacity: 0;
    // background-color: rgba($c-black, .3);
    background-color: $c-gray-25;
    pointer-events: none;

    &.active {
      transition: opacity $tr 0s;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__close {
    display: none;
    position: absolute;
    z-index: 1;
    top: 72px;
    right: gridPart(0, 0, 1, gridBase(12, 11, 2));
    width: 32px;
    height: 32px;
    cursor: pointer;

    @include rwd('tablet') {
      display: block;
    }

    &::after {
      @include icon;
      @include icon-center(22px, 22px);
      content: $icon-close-thin;
      transition: color $tr, transform $tr;
      color: $c-white;
    }

    &:hover {
      &::after {
        transform: rotate(180deg);
        color: rgba($c-white, 1);
      }
    }
  }
}
