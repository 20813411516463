@mixin font-face($url, $family, $prefix, $fonts) {
  @if (type-of($fonts) == 'list') {
    @each $font in $fonts {
      @font-face {
        font-family: $family;
        font-style: #{(map-get($font, 'style'))};
        font-weight: #{(map-get($font, 'weight'))};
        src:
          url('#{$url}#{$prefix}-#{(map-get($font, "name"))}.woff2') format('woff2'),
          url('#{$url}#{$prefix}-#{(map-get($font, "name"))}.woff') format('woff'),
          url('#{$url}#{$prefix}-#{(map-get($font, "name"))}.ttf') format('truetype');
        font-display: swap;
      }
    }

  } @else {

    @font-face {
      font-family: $family;
      font-style: #{map-get($fonts, 'style')};
      font-weight: #{map-get($fonts, 'weight')};
      src:
        url('#{$url}#{$prefix}-#{map-get($fonts, "name")}.woff2') format('woff2'),
        url('#{$url}#{$prefix}-#{map-get($fonts, "name")}.woff') format('woff'),
        url('#{$url}#{$prefix}-#{map-get($fonts, "name")}.ttf') format('truetype');
      font-display: swap;
    }
  }
}

/*

  Sample file name:
    • OpenSans-Bold.ttf
    • [prefix][delimiter][font_weight].ttf

  Mixin args:
    • path
    • font family
    • fonts weights:
      • name (font_weight in file names)
      • weight
      • style

  Example:
    $Roboto : (
      (
        'name'   : 'Regular',
        'weight' : 400,
        'style'  : 'normal'
      ),
      (
        'name'   : 'Medium',
        'weight' : 500,
        'style'  : 'normal'
      ),
      (
        'name'   : 'Bold',
        'weight' : 700,
        'style'  : 'normal'
      )
    );

    @include fontFace('../sources/fonts/font/', 'Roboto', 'Roboto', $Roboto);

*/



$fw-thin:       100;
$fw-extralight: 200;
$fw-light:      300;
$fw-regular:    400;
$fw-medium:     500;
$fw-semibold:   600;
$fw-bold:       700;
$fw-extrabold:  800;
$fw-black:      900;


$f-font-name-01: 'AcuminPro';
$f-font-cfg-01: (
  // (
  //   'name': 'Thin',
  //   'weight': $fw-thin,
  //   'style': 'normal'
  // ),
  // (
  //   'name': 'Light',
  //   'weight': $fw-light,
  //   'style': 'normal'
  // ),
  (
    'name': 'Regular',
    'weight': $fw-regular,
    'style': 'normal'
  ),
  // (
  //   'name': 'Medium',
  //   'weight': $fw-medium,
  //   'style': 'normal'
  // ),
  (
    'name': 'SemiBold',
    'weight': $fw-semibold,
    'style': 'normal'
  ),
  // (
  //   'name': 'Bold',
  //   'weight': $fw-bold,
  //   'style': 'normal'
  // ),
  // (
  //   'name': 'ExtraBold',
  //   'weight': $fw-extrabold,
  //   'style': 'normal'
  // ),
  // (
  //   'name': 'Black',
  //   'weight': $fw-black,
  //   'style': 'normal'
  // ),
);

$f-font-name-02: 'RobotoMono';
$f-font-cfg-02: (
  (
    'name': 'Light',
    'weight': $fw-light,
    'style': 'normal'
  ),
  (
    'name': 'Regular',
    'weight': $fw-regular,
    'style': 'normal'
  ),
  (
    'name': 'Medium',
    'weight': $fw-medium,
    'style': 'normal'
  ),
  // (
  //   'name': 'SemiBold',
  //   'weight': $fw-semibold,
  //   'style': 'normal'
  // ),
  // (
  //   'name': 'Bold',
  //   'weight': $fw-bold,
  //   'style': 'normal'
  // ),
  // (
  //   'name': 'Black',
  //   'weight': $fw-black,
  //   'style': 'normal'
  // ),
);

// $f-font-name-03: 'Bukra';
// $f-font-cfg-03: (
//   (
//     'name': 'Regular',
//     'weight': $fw-regular,
//     'style': 'normal'
//   ),
// );

@include font-face('#{$font-path}/#{$f-font-name-01}/', $f-font-name-01, $f-font-name-01, $f-font-cfg-01);
@include font-face('#{$font-path}/#{$f-font-name-02}/', $f-font-name-02, $f-font-name-02, $f-font-cfg-02);
// @include font-face('#{$font-path}/#{$f-font-name-03}/', $f-font-name-03, $f-font-name-03, $f-font-cfg-03);


$font-family-fallback: tahoma, arial, sans-serif;

$f-inter: $f-font-name-01, $font-family-fallback;
$f-prim: $f-inter;

$f-ivymode: $f-font-name-02, $font-family-fallback;
$f-sec: $f-ivymode;

// $f-bukra: $f-font-name-03, $font-family-fallback;
// $f-tert: $f-bukra;

.f-prim { font-family: $f-prim; }
.f-sec { font-family: $f-sec; }
// .f-tert { font-family: $f-tert; }

$ff-prim: $f-prim;
$ff-sec: $f-sec;

$fs-base: 1.7rem;
