.teamBoxes {
  @include fluid-spacing('padding-top', 90, 160);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__label {
    text-align: center;
  }

  &__heading {
    width: 100%;
    max-width: 726px;
    margin-top: 44px;
    margin-right: auto;
    margin-left: auto;
    // padding-right: gridPart(2, 2, 0, gridBase(12, 11, 0));
    // padding-left: gridPart(2, 2, 0, gridBase(12, 11, 0));
    text-align: center;

    &--bigger {
      max-width: none;
      padding-right: gridPart(1, 0, 0, gridBase(12, 11, 0));
      padding-left: gridPart(1, 0, 0, gridBase(12, 11, 0));
    }
  }

  &__boxes {
    margin-top: 80px;
    margin-right: -20px;
    margin-left: -20px;

    @include rwd('tablet') {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__link {
    @include fluid-spacing('margin-top', 56, 72);
    position: relative;
    transition: color $tr;
    color: $c-black;
    font-weight: $fw-semibold;
    line-height: calc(18 / 16);

    &::after {
      @include icon;
      @include icon-right(10px, 10px);
      content: $icon-arrow-small;
      right: -18px;
      transition: color $tr, transform $tr;
      color: $c-black;
    }

    &:hover {
      color: $c-gray-6a;

      &::after {
        transform: translate3d(2px, 2px, 0);
        color: $c-gray-6a;
      }
    }
  }

  &__swiper {
    &.disabled {
      .swiper-wrapper {
        justify-content: center;
        transform: translate3d(0, 0, 0) !important;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 42px;
      height: 42px;
      transition: background-color $tr;
      border: 1px solid $c-gray;
      border-radius: 50%;
      background-color: $c-gray;

      &::after {
        @include icon;
        @include icon-center(14px, 14px);
        transition: color $tr;
        color: $c-white;
      }
    }

    .swiper-button-prev {
      left: 20px;

      // @include rwd('large-phone') {
      //   left: -21px;
      // }

      &::after {
        content: $icon-arrow-left;
      }
    }

    .swiper-button-next {
      right: 20px;

      // @include rwd('large-phone') {
      //   right: -21px;
      // }

      &::after {
        content: $icon-arrow-right;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      &:hover {
        background-color: $c-gray-6a;
      }
    }

    .swiper-slide {
      // max-width: gridPart(3, 2, 0, gridBase(12, 11, 0));
      max-width: 280px;
    }
  }

  &__box {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-width: 0;
    max-width: 280px;
    height: 100%;
    min-height: 0;
    max-height: 427px;
    background-color: $c-white;
    color: $c-black;
    aspect-ratio: calc(280/427);
  }

  &__function {
    margin-top: 8px;
    text-align: left;
  }
}
