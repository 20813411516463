.main {
  // padding-top: #{$header-height}px;
  // transition: transform .5s;
  // overflow-x: hidden;
  padding: 72px 20px 0;
  background-color: $c-back;

  @include parent('.theme-black', '') {
    background-color: $c-gray-25;
    color: $c-white;
  }

  @include parent('.theme-gray', '') {
    background-color: $c-gray-e6;
  }

  @include rwd('tablet') {
    padding: 0;
  }

  &--noPadding {
    padding: 0;
  }

  &--noMobilePadding {
    padding: 0;
  }
}
